import React from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  ListItemIcon,
} from '@mui/material';
import GlassCard from '../customs/GlassCard';
import HeaderCard from '../layout/customs/header/HeaderCard';
import GavelIcon from '@mui/icons-material/Gavel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { angelTerms } from './termContents';

const AngelTerms = () => {
  return (
    <Container>
      <HeaderCard
        title='TERMS AND CONDITIONS'
        icon={<GavelIcon sx={{ fontSize: 60 }} />}
      />
      <GlassCard>
        <Typography variant='h6' component='h6' gutterBottom>
          Angel Terms and Conditions
        </Typography>
        <Box>
          <List>
            {angelTerms.map((term) => (
              <ListItem key={term.id} disablePadding sx={{ marginBottom: 2 }}>
                <ListItemIcon sx={{ color: 'white' }}>
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText>{term.content}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </GlassCard>
    </Container>
  );
};

export default AngelTerms;
