import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

export const getParentPickupInfo = createAsyncThunk(
  'parentInfo/getParentPickupInfo',
  async (pickupId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/parent/all?pickupId=${pickupId}`);
      const data = formatData(response.data.data[0]); // Assuming formatData is defined
      return data;
    } catch (error) {
      return rejectWithValue('Sorry, We could not find your pickup information');
    }
  }
);

export const updateParentPickup = createAsyncThunk(
  'parentInfo/updateParentPickup',
  async (data, { rejectWithValue }) => {
    const pickupObj = {
      pickup: true,
      confirmCode: data.confirmCode,
    };

    try {
      const response = await axios.put(`${API_URL}/parent/pickup/admin-code/${data.pickupId}`, pickupObj, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data?.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'incorrect admin code');
    }
  }
);

const initialParentInfoState = {
  parentEntries: [],
  childrenEntries: [],
  approved: null,
  loading: false,
  error: null,
  success: false,
  pickup: null,
  updateLoading: false,
  updateSuccess: false,
  updateError: null,
  updateMessage: null,
};

const parentInfoSlice = createSlice({
  name: 'parentInfo',
  initialState: initialParentInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getParentPickupInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getParentPickupInfo.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.loading = false;
        state.success = true;
      })
      .addCase(getParentPickupInfo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(updateParentPickup.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateParentPickup.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.updateSuccess = true;
        state.pickup = true;
        state.updateMessage = action.payload;
      })
      .addCase(updateParentPickup.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.payload;
      });
  },
});

const formatData = (data) => {
  const parent = {
    'Angel Code': data.parentId,
    'Full Name': data.parentName,
    'Student ID': data.studentId,
    'Phone Number': data.phone,
    Email: data.email,
    'Pick-up Location':
      data.pickupLocation === 'Abbotsford'
        ? 'Abbotsford: Abby Campus, S Building'
        : data.pickupLocation === 'Chilliwack'
          ? 'Chilliwack: CEP Campus, A Building'
          : '',
    'Additional Person':
      data.additionalPerson === '' ? 'None' : data.additionalPerson,
    'Has pickuped?': data.pickup,
    'Pickup Time': data.pickupTime !== null ? data.pickupTime : 'None',
  };

  const parentEntries = Object.entries(parent);

  const children = [];
  for (let i = 0; i < data.children.length; i++) {
    const child = {
      'Angel Code': data.children[i].childId,
      'First Name': data.children[i].childName,
      Age: data.children[i].age,
      Gender: data.children[i].gender,
      Wish: data.children[i].wish,
    };
    children.push(child);
  }

  const childrenEntries = [];
  children.forEach((child) => {
    const childEntry = Object.entries(child);

    childrenEntries.push(childEntry);
  });

  const entries = {
    parentEntries,
    childrenEntries,
    approved: data.approved,
    pickup: data.pickup === 'Yes' ? true : false,
  };
  return entries;
};

export default parentInfoSlice.reducer;
