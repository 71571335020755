import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addOneDay } from '../helpers/DateHelper';

// Date Format as MM/DD/YYYY
const initialConfigurationState = () => {
    const state = {
        configs: [
            {
                key: "angel-tree-timeline",
                value: {
                    registration: {
                        startDate: '11/01/2024',
                        endDate: '11/07/2024',
                        endDateCheck: ''    // Do not set date here
                    },
                    sponsorship: {
                        startDate: '11/12/2024',
                        endDate: '12/04/2024',
                        endDateCheck: ''    // Do not set date here
                    },
                    dropoff: {
                        startDate: '11/12/2024',
                        endDate: '12/04/2024',
                        endDateCheck: ''    // Do not set date here
                    },
                    pickup: {
                        startDate: '12/10/2024',
                        endDate: '12/11/2024',
                        endDateCheck: ''    // Do not set date here
                    }
                },
            },
            {
                key: 'enable-qr-code-after-form-submission',
                value: false
            }
        ],
        loading: false,
        success: false,
        error: null,
    };

    // Find the configuration with the key 'angel-tree-timeline'
    const angelTreeTimelineConfig = state.configs.find(config => config.key === 'angel-tree-timeline');

    // Automatically update endDateCheck for empty values within 'angel-tree-timeline'
    if (angelTreeTimelineConfig) {
        Object.keys(angelTreeTimelineConfig.value).forEach((key) => {
            const config = angelTreeTimelineConfig.value[key];
            if (config.endDateCheck === '') {
                config.endDateCheck = addOneDay(config.endDate);
            }
        });
    }

    return state;
}

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: initialConfigurationState,
    reducers: {},
},
);

const configsState = (configs) => configs;
const configKey = (state, key) => key;

export const selectConfigByKey = createSelector([configsState, configKey], (configs, key) => configs.length > 0 ? configs.find(config => config.key === key).value : null);

export default configurationSlice.reducer;
