import React, { useState, useEffect } from 'react';

// Import components
import {
  Box,
  Typography,
  FormControl,
  Button,
  Stack,
  FormControlLabel,
  Link,
  FormGroup,
  Checkbox,
} from '@mui/material';
import GlassCard from '../../../customs/GlassCard';
import SponsorFormContainer from '../SponsorFormContainer';

import CustomStepper from '../CustomStepper';
import PreviewInfo from './PreviewInfo';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';

import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
// Import sponsor actions
import {
  setSponsorFormValues,
  filterSelectedChildren,
  submitSponsorFormToServer,
} from '../../sponsorFormSlice';

const Preview = ({ step, prevStep, nextStep, setStep }) => {
  const [isChecked, setIsChecked] = useState(false);
  const form = useSelector((state) => state.sponsor.sponsorForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { values, submitSuccess, submitLoading } = form;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const goBack = () => {
    prevStep();
  };

  const handleSubmit = () => {
    if (!isChecked) {
      toast.error('Please fill in the terms and conditions section', {
        style: { textAlign: 'center' },
      });
    } else {
      dispatch(submitSponsorFormToServer(values))
        .unwrap()
        .then(() => {
          navigate('/sponsor/form/finish');
        })
        .catch((err) => {
          toast.warning(err.message);
          if (err.hasOwnProperty('data')) {
            const childrenObj = {
              selectedChildrenIds: err?.data?.newSelectedChildrenIds,
            };
            dispatch(setSponsorFormValues(childrenObj));
            dispatch(filterSelectedChildren());
          }
        });
    }
  };

  if (submitSuccess) {
    return <Navigate to='/sponsor/form/finish' replace />;
  }

  return (
    <SponsorFormContainer>
      <CustomStepper step={step} />
      <PreviewInfo setStep={setStep} />
      <GlassCard sx={{ marginTop: 5, width: '100%' }}>
        <FormControl component='fieldset' sx={{ marginBottom: '2rem' }}>
          <Box>
            <Typography
              variant='h5'
              component='h5'
              sx={{ marginBottom: '2rem' }}
            >
              Terms and Conditions
            </Typography>
          </Box>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: '#FDB91B',
                    },
                  }}
                  checked={isChecked}
                  onChange={() => setIsChecked((value) => !value)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={
                <>
                  I acknowledge, that I have read, understood and agreed all the{' '}
                  <Link
                    color='inherit'
                    href='/legal/terms-sponsor'
                    target='_blank'
                  >
                    terms and conditions
                  </Link>
                  .
                </>
              }
            />
          </FormGroup>
        </FormControl>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 4, sm: 4, md: 4 }}
          justifyContent='space-between'
          marginTop='2rem'
        >
          <Button
            color='secondary'
            size='large'
            variant='outlined'
            onClick={goBack}
          >
            Previous
          </Button>
          <LoadingButton
            color='secondary'
            size='large'
            endIcon={<SendIcon />}
            loading={submitLoading}
            loadingPosition='end'
            variant='contained'
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Stack>
      </GlassCard>
    </SponsorFormContainer>
  );
};

export default Preview;
