import React, { useState } from 'react';

// Import components
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Button,
  Stack,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import GlassCard from '../../../customs/GlassCard';
import { CustomTextField } from '../../../customs/CustomTextField';
import { CustomSelectField } from '../../../customs/CustomSelectField';
import CustomStepper from '../CustomStepper';
import FormContainer from '../../../customs/FormContainer';
import ParentFormContainer from '../ParentFormContainer';
import FAQDialog from '../FAQDialog';

// Import React-Hook-Forms and yup
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
// Import parent reducers
import { setParentFormValues } from '../../parentFormSlice';

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number')
    .required('Full Name Required'),
  studentId: yup
    .string()
    .required('Student ID required')
    .matches(/^[1-3][0-9][0-9]\d+$\S*$/, 'Invalid Student ID')
    .min(9, 'Student Id must be 9 digits')
    .max(9, 'Student Id must be 9 digits'),
  email: yup
    .string()
    .email('Incorrect Email Format')
    .required('Email Required'),
  pickupLocation: yup.string().required('Pick-up location required'),
  additionalPerson: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number'),
  phone: yup
    .string()
    .required('Phone number required')
    .min(14, 'Phone number must have 10 digits')
    .max(14, 'Phone number must have 10 digits'),
  smsUpdate: yup
    .boolean()
});

const ParentInfo = ({ step, nextStep }) => {
  const [open, setOpen] = useState(false);

  const formValues = useSelector((state) => state.parent.parentForm.values);
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: formValues.name,
      studentId: formValues.studentId,
      email: formValues.email,
      phone: formValues.phone,
      pickupLocation: formValues.pickupLocation,
      additionalPerson: formValues.additionalPerson,
      smsUpdate: formValues.smsUpdate
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const goNext = (data) => {
    dispatch(setParentFormValues(data));
    nextStep();
  };

  const normalizePhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value, 'CA');

    if (!phoneNumber) return value;

    return phoneNumber.formatNational();
  };

  return (
    <ParentFormContainer>
      <CustomStepper step={step} />
      <GlassCard sx={{ marginTop: 5, width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ alignSelf: 'center' }} variant='h5' component='h5'>
            Caregiver's information
          </Typography>
          <IconButton
            color='secondary'
            aria-label='delete'
            sx={{ fontSize: '30px' }}
            onClick={() => setOpen((prev) => !prev)}
          >
            <HelpIcon fontSize='inherit' />
          </IconButton>
        </Box>

        <FormContainer>
          <form onSubmit={handleSubmit(goNext)}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='flex-start'
              marginTop='2rem'
              marginBottom='2rem'
            >
              <CustomTextField
                label='Full Name'
                type='text'
                variant='outlined'
                placeholder='John Doe'
                focusColor='#FDB91B'
                fullWidth
                {...register('name')}
                helperText={errors?.name?.message}
              />

              <CustomTextField
                label='Student ID'
                type='text'
                variant='outlined'
                placeholder='Your UFV Student ID'
                focusColor='#FDB91B'
                fullWidth
                {...register('studentId')}
                helperText={errors?.studentId?.message}
              />
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='flex-start'
              marginTop='2rem'
              marginBottom='2rem'
            >
              <CustomTextField
                label='Email'
                type='email'
                variant='outlined'
                placeholder='john.doe@gmail.com'
                focusColor='#FDB91B'
                fullWidth
                {...register('email')}
                helperText={errors?.email?.message}
              />

              {/* Using Controller to change the format of the phone when user inputs general phone number */}
              <Controller
                name='phone'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    label='Phone Number'
                    type='text'
                    variant='outlined'
                    placeholder='604-123-4567'
                    focusColor='#FDB91B'
                    fullWidth
                    helperText={errors?.phone?.message}
                    onChange={(event) =>
                      field.onChange(normalizePhoneNumber(event.target.value))
                    }
                  />
                )}
              />
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='flex-start'
              marginTop='2rem'
              marginBottom='2rem'
            >
              <FormControl fullWidth>
                <InputLabel
                  id='pickup-location-label'
                  sx={{
                    '&.Mui-focused': {
                      color: '#FDB91B',
                    },
                    color: 'white',
                  }}
                >
                  Pick-up Location
                </InputLabel>

                <Controller
                  name='pickupLocation'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <CustomSelectField
                      {...field}
                      label='Pick-up Location'
                      labelId='pickup-location-label'
                      id='pickup-location'
                      sx={{ width: '100%' }}
                    >
                      <MenuItem value='' disabled>
                        <em style={{ color: '#CE4D4D' }}>
                          Please choose a location
                        </em>
                      </MenuItem>
                      <MenuItem value={'Abbotsford'}>
                        Abbotsford: Abby Campus, S Building
                      </MenuItem>
                      <MenuItem value={'Chilliwack'}>
                        Chilliwack: CEP Campus, A Building
                      </MenuItem>
                    </CustomSelectField>
                  )}
                />

                {errors?.pickupLocation?.message && (
                  <FormHelperText sx={{ color: 'yellow' }}>
                    {errors?.pickupLocation?.message}
                  </FormHelperText>
                )}
              </FormControl>

              <CustomTextField
                label='Additional Person'
                type='text'
                variant='outlined'
                placeholder='Alternative person for pick up (optional)'
                focusColor='#FDB91B'
                fullWidth
                {...register('additionalPerson')}
                helperText={errors?.additionalPerson?.message}
              />
            </Stack>

            <FormGroup>
              <FormControlLabel control={<Controller
                name='smsUpdate'
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <>
                    <Checkbox
                      {...field}
                      checked={!!field.value}
                      color='secondary' />
                  </>
                )}
              />} label="I would like to get text message updates on the phone number provided in this form" />
            </FormGroup>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='flex-end'
              marginTop='2rem'
            >
              <Button
                type='submit'
                color='secondary'
                size='large'
                variant='contained'
              >
                Next
              </Button>
            </Stack>
          </form>
        </FormContainer>
      </GlassCard>

      <FAQDialog open={open} setOpen={setOpen} />
    </ParentFormContainer>
  );
};

export default ParentInfo;
