import React from 'react';
import { Container, Link, Typography } from '@mui/material';
import GlassCard from '../../customs/GlassCard';
import SponsorFormContainer from './SponsorFormContainer';
import useTimeline from '../../../hooks/useTimeline';

const SponsorFormClosed = ({ angelTreeTimeline }) => {
    let openPeriod = useTimeline(angelTreeTimeline?.sponsorship?.startDate, angelTreeTimeline?.sponsorship?.endDate, true, true);
    let currentYear = new Date(angelTreeTimeline?.sponsorship?.startDate).getFullYear();

    return (
        <SponsorFormContainer>
            <Container sx={{ display: 'flex', justifyContent: 'center'}}>
                <GlassCard sx={{ marginTop: 5, width: '70%', textAlign: 'center'}}>
                    <Typography variant='h6' component='h6' color='secondary'>
                    We appreciate your interest in the { currentYear } Angel Tree Program!
                    </Typography>

                    <br/>

                    <Typography variant='h6' component='h6' color='secondary'>
                        {new Date().getTime() <= new Date(angelTreeTimeline.sponsorship.endDateCheck).getTime() ? `The Angel Sponsorship period is from ${openPeriod}` : `We are sorry, but the Angel sponsorship closed on ${new Date(angelTreeTimeline.sponsorship?.endDate).toLocaleString(undefined, { dateStyle: 'long' })}. Thank you for participating in the Angel Tree program this year`}. If
                        you have any questions, please contact us at{' '}
                        <Link color='secondary' href='mailto:angeltree@ufvsus.ca'>
                            angeltree@ufvsus.ca
                        </Link>
                        .
                    </Typography>
                </GlassCard>
            </Container>
        </SponsorFormContainer>
    );
};

export default SponsorFormClosed;
