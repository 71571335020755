export const angelTerms = [
  {
    id: 1,
    content:
      'To be eligible for the Angel Tree Program, you must be a current UFV student with an active student number (ID) and a guardian for children 16 or younger. If you are unsure if you fall within the criteria for the Angel Tree, we would like to encourage you to apply for review. It is important to note that proof of guardianship might be requested at any time.',
  },
  {
    id: 2,
    content:
      'By agreeing to these terms and conditions, you consent to your email being saved and used for future UFV Student Union Society Angel Tree communication purposes. You can unsubscribe from receiving these emails by contacting us directly at angeltree@ufvsus.ca or unsubscribing at the bottom of the email.',
  },
  {
    id: 3,
    content:
      'By agreeing to our terms and conditions, you automatically consent to receive marketing SMS communications from the UFV Student Union Society. These communications may include reminders and information related to the Angel Tree Program. Please note that message and data rates may apply, and the frequency of messages may vary. If you would like to unsubscribe at any point, you can reply STOP to any message or click on the unsubscribe link in our messages.',
  },
  {
    id: 4,
    content:
      'By agreeing to these terms and conditions, you understand that the wish(es) presented will be filled as closely as possible, but there is no guarantee that wishes will be fulfilled precisely as requested.',
  },

  {
    id: 5,
    content:
      'By agreeing to these terms and conditions, you agree to pick up the gifts on the day and time agreed upon. Please keep in mind that if you are unable to come, you can contact the SUS Office ahead of time and provide the contact information for the person picking up on your behalf. If something comes up and you can no longer make your pick-up time, please contact the SUS Office to arrange another time. Unfortunately, we do not have the capacity to offer any drop-offs; Angel guardians must pick up all wishes from either the Abbotsford or Chilliwack UFV Campuses.',
  },
  {
    id: 6,
    content:
      'Upon arrival, you will be asked to show a piece of ID and your confirmation QR code; you may also be required to sign a pick-up confirmation form. This helps ensure that all gifts have been picked up and that all Angels receive their wishes. By agreeing to these terms and conditions, you consent to abide by these processes. Confirmation forms will be confidential and stored as such.',
  },
];

export const sponsorTerms = [
  {
    id: 1,
    content:
      'By agreeing to these terms and conditions, you consent to your email being saved and used for future UFV Student Union Society Angel Tree communication purposes. You can unsubscribe from receiving these emails by contacting us directly at angeltree@ufvsus.ca or unsubscribing at the bottom of the email.',
  },
  {
    id: 2,
    content:
      'By agreeing to these terms and conditions, you agree to fulfill ($50-$75) the wishes of the Angel you have chosen to sponsor. You agree to drop off all gifts within the advertised dates and selected location.',
  },
  {
    id: 3,
    content: 'By agreeing to our terms and conditions, you automatically consent to receive marketing SMS communications from the UFV Student Union Society. These communications may include reminders and information related to the Angel Tree Program. Please note that message and data rates may apply, and the frequency of messages may vary. If you would like to unsubscribe at any point, you can reply STOP to any message or click on the unsubscribe link in our messages.',
  },
  {
    id: 4,
    content:
      'By agreeing to these terms and conditions, you commit to dropping off gifts and provide your confirmation QR code at the drop-off time and location that is agreed upon. If an issue arises and you will not be able to drop off your gifts at the predetermined time, don\'t hesitate to get in touch with SUS staff ahead of time to arrange another drop-off time at angeltree@ufvsus.ca. Unfortunately, we cannot arrange a pick-up; all gifts must be dropped off at the Abbotsford or Chilliwack UFV Campuses.',
  },
];
