import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import GlassCard from '../../customs/GlassCard';
import styles from './SponsorDropoff.module.css';

const ChildCard = ({ child }) => {
  return (
    <GlassCard className={styles.card}>
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <img
          src='/assets/images/angel_halo.png'
          alt='hero girl'
          className={styles.angelHalo}
        />
      </Box>
      <Box className={styles.angelCardContent}>
        <Typography variant='h5' component='h5' gutterBottom>
          Hi, My name is{' '}
          <span className={styles.childFont}>{child.childName}</span>.
        </Typography>
        <Typography variant='h5' component='h5' gutterBottom>
          I am <span className={styles.childFont}>{child.age}</span> years old.
        </Typography>
        <Typography variant='h5' component='h5' gutterBottom>
          With your help, my wish can come true.
        </Typography>
        <Divider className={styles.angelCardDivider} />

        <Typography variant='h5' component='h5' gutterBottom>
          This season, I wish to get:
        </Typography>
        <Typography variant='h5' component='h5' gutterBottom>
          <span className={styles.childFont}>{child.wish}</span>
        </Typography>
      </Box>
      <Typography sx={{ textAlign: 'right' }} variant='h6' component='p'>
        {child.childId}
      </Typography>
    </GlassCard>
  );
};

export default ChildCard;
