import { TextField, styled } from '@mui/material';

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
})((p) => ({
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },

  '& .MuiInputLabel-root': {
    color: 'white',
  },

  '& .MuiFormHelperText-root': {
    color: 'yellow',
  },

  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },

  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: p.focusColor,
    },
    '&:hover fieldset': {
      borderColor: p.focusColor,
    },
  },

  '& .MuiInput-root': {
    '&:before': {
      borderBottomColor: 'white',
    },
    '&:hover:before': {
      borderColor: p.focusColor,
    },
  },
}));
