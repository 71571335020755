import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

export const submitParentFormToServer = createAsyncThunk(
  'parentForm/submitParentForm',
  async (data, { rejectWithValue }) => {
    const formData = {
      name: data.name,
      email: data.email,
      studentId: data.studentId,
      phone: data.phone,
      smsUpdate: data.smsUpdate,
      pickup: {
        pickupLocation: data.pickupLocation,
        additionalPerson: data?.additionalPerson,
      },
      children: data.children,
    };
    
    try {
      const response = await axios.post(`${API_URL}/parent`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Your form could not be submitted. Please contact an administrator.'
      );
    }
  }
);

const initialParentFormState = {
  values: {},
  loading: false,
  success: false,
  error: null,
  data: {},
};

const parentFormSlice = createSlice({
  name: 'parentForm',
  initialState: initialParentFormState,
  reducers: {
    setParentFormValues: (state, action) => {
      // Merge the existing state.values with the new values from the action payload
      state.values = { ...state.values, ...action.payload };
    },
    resetParentFormError: (state) => {
      state.error = null;
    },
    resetParentFormValues: (state) => {
      Object.assign(state, initialParentFormState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitParentFormToServer.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitParentFormToServer.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.success = true;
      })
      .addCase(submitParentFormToServer.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const { setParentFormValues, resetParentFormError, resetParentFormValues } = parentFormSlice.actions;

export default parentFormSlice.reducer;
