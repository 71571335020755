import React from 'react';
import { Link, Typography } from '@mui/material';
import GlassCard from '../../customs/GlassCard';
import ParentFormContainer from './ParentFormContainer';
import useTimeline from '../../../hooks/useTimeline';

const ParentFormClosed = ({ angelTreeTimeline }) => {
  let openPeriod = useTimeline(angelTreeTimeline?.registration?.startDate, angelTreeTimeline?.registration?.endDate, true, true);
  let currentYear = new Date(angelTreeTimeline?.registration?.startDate).getFullYear();

  return (
    <ParentFormContainer sx={{ display: 'flex', justifyContent: 'center' }}>
      <GlassCard sx={{ marginTop: 5, width: '70%', textAlign: 'center' }}>
        <Typography variant='h6' component='h6' color='secondary'>
          We appreciate your interest in the { currentYear } Angel Tree Program!
        </Typography>

        <br />
        <Typography variant='h6' component='h6' color='secondary'>
          {new Date().getTime() <= new Date(angelTreeTimeline?.registration?.endDateCheck).getTime() ? `The Angel Registration period is from ${openPeriod}` : `We are sorry, but the Angel registration closed on ${new Date(angelTreeTimeline?.registration?.endDate).toLocaleString(undefined, { dateStyle: 'long' })}. Thank you for participating in the Angel Tree program this year`}. If
          you have any questions, please contact us at{' '}
          <Link color='secondary' href='mailto:angeltree@ufvsus.ca'>
            angeltree@ufvsus.ca
          </Link>
          .
        </Typography>
      </GlassCard>
    </ParentFormContainer>
  );
};

export default ParentFormClosed;
