import React from 'react';
import { Box, Divider, Container } from '@mui/material';

import { StyledEngineProvider } from '@mui/material/styles';
import styles from './HomePage.module.css';

import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';

const HomePage = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Section1 />
      <Box className='divider-pattern'>
        <img
          src='/assets/images/pattern.png'
          alt='pattern divider'
          className={styles.pattern_img}
        />
      </Box>
      <Section2 />
      <Container sx={{ marginTop: 9, marginBottom: 6 }}>
        <Divider variant='fullWidth' sx={{ borderColor: '#A5A5A5' }} />
      </Container>
      <Section3 />
    </StyledEngineProvider>
  );
};

export default HomePage;
