import { combineReducers } from 'redux';

import parentFormSlice from '../components/parent/parentFormSlice';
import parentInfoSlice from '../components/parent/parentInfoSlice';

import sponsorFormSlice from '../components/sponsor/sponsorFormSlice';
import sponsorInfoSlice from '../components/sponsor/sponsorInfoSlice';

import configurationSlice from '../custom-slices/configurationSlice';

const parentReducer = combineReducers({
  parentForm: parentFormSlice,
  parentInfo: parentInfoSlice,
});

const sponsorReducer = combineReducers({
  sponsorForm: sponsorFormSlice,
  sponsorInfo: sponsorInfoSlice,
});

export default combineReducers({
  parent: parentReducer,
  sponsor: sponsorReducer,
  configuration: configurationSlice
});
