import React, { useState, useEffect } from 'react';

// Import components
import {
  Box,
  Typography,
  FormControl,
  Button,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
} from '@mui/material';
import GlassCard from '../../../customs/GlassCard';
import ParentFormContainer from '../ParentFormContainer';
import CustomStepper from '../CustomStepper';
import PreviewInfo from './PreviewInfo';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';

import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
// Import parent reducers
import {
  submitParentFormToServer,
} from '../../parentFormSlice';

const Preview = ({ step, prevStep, setStep }) => {
  const [checkedTerm, setCheckedTerm] = useState(null);

  const form = useSelector((state) => state.parent.parentForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { values, success, loading } = form;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleChange = (event) => {
    let checkedTermValue = event.target.value === 'true' ? true : false;
    setCheckedTerm(checkedTermValue)
  };

  const goBack = () => {
    prevStep();
  };

  const handleSubmit = () => {
    if (checkedTerm === null) {
      toast.error('Please fill in the terms and conditions section', {
        style: { textAlign: 'center' },
      });

      return;
    }
    dispatch(submitParentFormToServer(values))
      .unwrap()
      .then(() => {
        navigate('/parent/form/finish');
      })
      .catch((err) => {
        toast.error(err);
      });

  };

  if (success) {
    return <Navigate to='/parent/form/finish' replace />;
  }

  return (
    <ParentFormContainer>
      <CustomStepper step={step} />
      <PreviewInfo setStep={setStep} />

      <GlassCard sx={{ marginTop: 5, width: '100%' }}>
        <FormControl component='fieldset' sx={{ marginBottom: '2rem' }}>
          <Box>
            <Typography
              variant='h5'
              component='h5'
              sx={{ marginBottom: '2rem' }}
            >
              Terms and Conditions
            </Typography>
          </Box>
          <RadioGroup
            aria-label='gender'
            name='controlled-radio-buttons-group'
            onChange={handleChange}
          >
            <FormControlLabel
              value='true'
              control={
                <Radio
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: '#FDB91B',
                    },
                  }}
                />
              }
              label={
                <>
                  I acknowledge that I have read, understood and met all the {' '}
                  <Link
                    color='inherit'
                    href='/legal/terms-angel'
                    target='_blank'
                    sx={{ textDecoration: 'none', fontWeight: 'bold' }}
                  >
                    Terms and Conditions
                  </Link>.
                </>
              }
            />
            <FormControlLabel
              value='false'
              control={
                <Radio
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: '#FDB91B',
                    },
                  }}
                />
              }
              label={
                <>
                  I have read but only meet some of the {' '}
                  <Link
                    color='inherit'
                    href='/legal/terms-angel'
                    target='_blank'
                    sx={{ textDecoration: 'none', fontWeight: 'bold' }}
                  >
                    Terms and Conditions
                  </Link>. However, I would still like to participate in the program.
                </>
              }
            />
          </RadioGroup>
        </FormControl>

        <Typography variant='caption' component='p'>
          By clicking the second option, an SUS team member will review your application and will be in contact
          to see if you are eligible to be a part of the program.
        </Typography>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 4, sm: 4, md: 4 }}
          justifyContent='space-between'
          marginTop='2rem'
        >
          <Button
            color='secondary'
            size='large'
            variant='outlined'
            onClick={goBack}
          >
            Previous
          </Button>
          <LoadingButton
            color='secondary'
            size='large'
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition='end'
            variant='contained'
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Stack>
      </GlassCard>
    </ParentFormContainer>
  );
};

export default Preview;
