import React from 'react';
import { Container, Box, Grid, Typography, Link } from '@mui/material';
import styles from './HomePage.module.css';

const contents = [
  {
    id: 1,
    content: `The Angel Tree Program has helped hundreds of children from our UFV community receive holiday wishes. 
    Despite the challenges and obstacles over the past years, 
    we have continued and adapted to offer this heartwarming program to our UFV community for over eight years. `,
  },
  {
    id: 2,
    content: `All current UFV students who are legal guardians to children 16 years or younger, 
    we encourage you to apply for this program. Additionally, we invite all local community members to 
    support this initiative's success by sponsoring an Angel or sharing the information within your community. 
    Together, let us make this year the best one yet!`,
  },
  {
    id: 3,
    content: `Thanks to everyone who has supported the Angel Tree Program in previous years. 
    We are excited to continue to grow this program within our community.`,
  },
  {
    id: 4,
    content: (
      <p>
        If you have any questions or require further information about this
        program, don't hesitate to get in touch with us at
        <Link color='secondary' href='mailto:angeltree@ufvsus.ca'>
          {' '}
          angeltree@ufvsus.ca
        </Link>
      </p>
    ),
  }
];

const Section2 = () => {
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Box className={styles.leftText}>
          <img
            src='/assets/images/star_star.png'
            alt='star'
            className={`homepage-images ${styles.image}`}
          />
          <Box>
            <Typography
              variant='h4'
              component='h4'
              sx={{ fontWeight: 'bold', marginTop: '2rem' }}
              className={styles.headline}
            >
              SUCCESSFUL MAGICAL CONNECTIONS
            </Typography>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box className={styles.rightText}>
              {contents.map((content) => (
                <Typography
                  key={content.id}
                  variant='div'
                  gutterBottom
                  component='div'
                  sx={{ fontSize: 22, marginTop: 6 }}
                >
                  {content.content}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Section2;
