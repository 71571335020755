import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;
const config = {
    headers: {
        'Content-Type': 'application/json',
    },
};

export const getSponsorDropoffInfo = createAsyncThunk('sponsorInfo/getSponsorDropoffInfo', async (dropoffId, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${API_URL}/sponsor/all?dropoffId=${dropoffId}`);
        return formatData(response.data.data[0]);
    } catch (error) {
        return rejectWithValue('Sorry, We could not find your drop off information');
    }
});

export const updateSponsorDropOff = createAsyncThunk('sponsorInfo/updateSponsorDropOff', async (data, { rejectWithValue }) => {
    const dropoffObj = {
        dropoff: true,
        confirmCode: data.confirmCode,
    };
    try {
        const response = await axios.put(`${API_URL}/sponsor/dropoff/admin-code/${data.dropoffId}`, dropoffObj, config);
        return response.data?.message;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Incorrect admin code');
    }
});

const initialSponsorInfoState = {
    sponsorEntries: [],
    childrenEntries: [],
    loading: false,
    error: null,
    success: false,
    dropoff: null,
    updateLoading: false,
    updateSuccess: false,
    updateError: null,
    updateMessage: null,
};

const sponsorInfoSlice = createSlice({
    name: 'sponsorInfo',
    initialState: initialSponsorInfoState,
    reducers: {
        // Add any additional synchronous actions here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSponsorDropoffInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSponsorDropoffInfo.fulfilled, (state, action) => {
                state.sponsorEntries = action.payload.sponsorEntries;
                state.childrenEntries = action.payload.childrenEntries;
                state.dropoff = action.payload.dropoff;
                state.loading = false;
                state.success = true;
            })
            .addCase(getSponsorDropoffInfo.rejected, (state, action) => {
                state.success = false;
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateSponsorDropOff.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(updateSponsorDropOff.fulfilled, (state, action) => {
                state.updateLoading = false;
                state.updateSuccess = true;
                state.dropoff = true;
                state.updateMessage = action.payload;
            })
            .addCase(updateSponsorDropOff.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            });
    },
});

export default sponsorInfoSlice.reducer;

const formatData = (data) => {
    const sponsor = {
        'Full Name': data.sponsorName,
        'Phone Number': data.phone,
        Email: data.email,
        'What describes you the best?': data.title,
        'Drop-off Location':
            data.dropoffLocation === 'Abbotsford'
                ? 'Abbotsford: Abby Campus, S Building'
                : data.dropoffLocation === 'Chilliwack'
                    ? 'Chilliwack: CEP Campus, A Building'
                    : '',
        'Has Dropped off?': data.dropoff,
        'Dropoff Time': data.dropoffTime !== null ? data.dropoffTime : 'None',
    };

    const sponsorEntries = Object.entries(sponsor);

    const entries = {
        sponsorEntries,
        childrenEntries: data.children,
        dropoff: data.dropoff === 'Yes' ? true : false,
    };
    return entries;
};
