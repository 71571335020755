import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import { Box, Container, Typography } from '@mui/material';
import CustomStepper from '../CustomStepper';
import SponsorFormContainer from '../SponsorFormContainer';
import Spinner from '../../../layout/Spinner';
import GlassCard from '../../../customs/GlassCard';
import ChildCard from '../ChildCard';
import AlertNav from './AlertNav';
import InfiniteScroll from 'react-infinite-scroll-component';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

import styles from '../SponsorForm.module.css';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
// Import sponsor actions
import {
  fetchChildren,
  filterSelectedChildren,
  setSponsorFormValues,
} from '../../sponsorFormSlice';

const perPage = 6;

const PickChildren = ({ step, nextStep }) => {
  const { width, height } = useWindowSize();

  const sponsorState = useSelector((state) => state.sponsor.sponsorForm);
  const { children, loading, values } = sponsorState;

  const dispatch = useDispatch();

  let pages = useMemo(() => {
    return Math.ceil(children.length / perPage);
  }, [children]);

  const [currentPage, setCurrentPage] = useState(1);

  const [childrenArray, setChildrenArray] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [selectedChildrenIds, setSelectedChildrenIds] = useState(
    new Set(values.selectedChildrenIds)
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    dispatch(fetchChildren(source.token));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    setChildrenArray(children.slice(0, 6));
  }, [children]);

  const selectedCount = selectedChildrenIds.size;

  const handleToggle = (child) => {
    // Create a copy (to avoid mutation).
    const nextChildren = new Set(selectedChildrenIds);

    if (nextChildren.has(child)) {
      nextChildren.delete(child);
    } else nextChildren.add(child);

    setSelectedChildrenIds(nextChildren);

    const childrenObj = {
      selectedChildrenIds: Array.from(nextChildren),
    };
    dispatch(setSponsorFormValues(childrenObj));

    dispatch(filterSelectedChildren());
  };

  const handleLoadMore = () => {
    if (childrenArray.length >= children.length) {
      setHasMore(false);
      return;
    }
    setCurrentPage((prevVal) => (prevVal === pages ? prevVal : prevVal + 1));
    setTimeout(() => {
      setChildrenArray(
        childrenArray.concat(
          children.slice(currentPage * perPage, (currentPage + 1) * perPage)
        )
      );
    }, 2000);
  };

  if (loading)
    return (
      <SponsorFormContainer>
        <Spinner />
      </SponsorFormContainer>
    );

  if (children.length === 0) {
    const d = new Date();
    let year = d.getFullYear();

    return (
      <SponsorFormContainer>
        <Confetti width={width} height={height} />
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <GlassCard sx={{ width: 'fit-content', marginTop: '2rem' }}>
            <Typography
              variant='h5'
              component='h5'
              color='secondary'
              textAlign='center'
            >
              {`We are thrilled to inform all Angels have been sponsored for Angel Tree ${year}. We are overwhelmed by the support and participation from our UFV and local community.`}
            </Typography>
          </GlassCard>
        </Container>
      </SponsorFormContainer>
    );
  }

  return (
    <SponsorFormContainer>
      {children.length > 0 ? (
        <>
          <Container>
            <CustomStepper step={step} />
            <Typography
              sx={{
                marginTop: '4rem',
                marginBottom: '4rem',
                textAlign: 'center',
                color: 'white',
              }}
              variant='h5'
              component='h5'
            >
              Click on an Angel card to select / deselect
            </Typography>
          </Container>
          {selectedCount > 0 && (
            <AlertNav
              selectedCount={selectedCount}
              nextStep={nextStep}
              selectedChildrenIds={selectedChildrenIds}
              setSelectedChildrenIds={setSelectedChildrenIds}
              onToggle={(child) => handleToggle(child.childId)}
            />
          )}

          <InfiniteScroll
            style={{ overflowY: 'hidden' }}
            dataLength={childrenArray.length}
            next={handleLoadMore}
            hasMore={hasMore}
            loader={<Spinner />}
          >
            <Box className={styles.angelWrapper}>
              <Box className={styles.angelContainer}>
                {childrenArray.map((child) => (
                  <ChildCard
                    key={child.childId}
                    child={child}
                    isSelected={selectedChildrenIds.has(child.childId)}
                    onToggle={() => handleToggle(child.childId)}
                  />
                ))}
              </Box>
            </Box>
          </InfiniteScroll>
        </>
      ) : (
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <GlassCard
            sx={{
              width: 'fit-content',
              marginTop: '2rem',
            }}
          >
            <Typography variant='h5' component='h5' color='yellow'>
              All angels have been sponsored.
            </Typography>
          </GlassCard>
        </Container>
      )}
    </SponsorFormContainer>
  );
};

export default PickChildren;
