import React, { forwardRef } from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import styles from '../SponsorForm.module.css';
import SelectedChildCard from './SelectedChildCard';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

// Import useSelector to manage the global state
import { useSelector } from 'react-redux';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const SelectedChildrenDialog = ({ open, setOpen, onToggle }) => {
  const sponsorState = useSelector((state) => state.sponsor.sponsorForm);
  const { selectedChildren } = sponsorState;

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: 'primary.main' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Selected Angels
          </Typography>
          <Button
            startIcon={<SaveIcon />}
            variant='contained'
            autoFocus
            color='secondary'
            onClick={handleClose}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ backgroundColor: '#026b64' }}>
        <DialogContentText
          id='alert-dialog-description'
          color='white'
          sx={{ textAlign: 'center' }}
        >
          Click on an Angel card remove the Angel
        </DialogContentText>

        {selectedChildren.length > 0 && (
          <Box>
            <Box className={styles.angelContainerPreview}>
              {selectedChildren.map((child) => (
                <SelectedChildCard
                  key={child.childId}
                  child={child}
                  onToggle={onToggle}
                />
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SelectedChildrenDialog;
