import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DropoffDetailDialog from '../DropoffDetailDialog';


const FAQDialog = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const [openDropoffDialog, setOpenDialogDropoff] = useState(false);

  const Applicants = () => {
    return <List>
      <ListItem>
        <ListItemIcon>
          <CheckBoxIcon color='primary' />
        </ListItemIcon>
        <ListItemText>You are a current UFV student</ListItemText>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <CheckBoxIcon color='primary' />
        </ListItemIcon>
        <ListItemText>
          You are the legal guardian for children 16 or younger
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <CheckBoxIcon color='primary' />
        </ListItemIcon>
        <ListItemText>You are able to pick up or arrange gift pick-up at either of our preferred locations.
        </ListItemText>
      </ListItem>
    </List>
  }

  const ProgramStages = () => {
    return <List>
      <ListItem>
        <ListItemText><span style={{ fontWeight: 'bold' }}>1. Registration for Angels:</span> Applications are open to
          eligible UFV students who are the guardians of children aged 16 or younger.
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemText><span style={{ fontWeight: 'bold' }}>2. Fulfilling Wishes:</span> The program opens to our UFV and external communities to
          sponsor and fulfill our Angel's holiday wishes.
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemText><span style={{ fontWeight: 'bold' }}>3. Drop-Off & Angel Pick-Up:</span> Angels collect gifts/wishes at designated locations.
        (Click <Button onClick={() => setOpenDialogDropoff(prev => !prev)} color='primary' size="small" variant="outlined">here</Button> for drop-off location & dates)
        </ListItemText>
      </ListItem>
    </List>
  }


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Sponsor Frequently Asked Questions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              What is the Angel Tree Program?
            </Typography>
            <Typography varient='p' component='p'>
              The Student Union Society operates the Angel Tree Program annually
              around the holiday season. The program supports UFV students who are
              raising families while pursuing their education and it is one of the
              ways we are giving back to our community.
            </Typography>
            <br />
            <Typography varient='p' component='p'>
              The program has three stages:
            </Typography>

            <ProgramStages />

            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              Who are Angels?
            </Typography>
            <Typography varient='p' component='p'>
              Angels are the children of UFV students who are accepted to be a part of the Angel Tree Program.
              Applicants must meet the following requirements to be accepted for the program:
            </Typography>

            <Applicants />

            <Typography variant='p' component='p' fontStyle='italic'>
              It is important to note that proof of guardianship might be requested at any time*
            </Typography>

            <br />

            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              How to select the Angel(s)
            </Typography>
            <Typography varient='p' component='p'>
              Simply click on the Angel card to select or unselect it. The selected cards will be highlighted,
              and the number of selected cards will be displayed at the top of your screen.
              You can choose to sponsor multiple Angels by selecting their cards.
            </Typography>
            <br />

            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              What are Angel's wishes?
            </Typography>
            <Typography varient='p' component='p'>
              During the holiday season, Angels create a wish list of gifts they would like to receive.
              Sponsors are not required to fulfill all wishes, and the average price range is $50 - $75 per Angel sponsorship.
            </Typography>
            <br />

            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              What if I can't find the exact wishes requested from my Angel?
            </Typography>
            <Typography varient='p' component='p'>
              We understand that there may be limitations during this holiday
              season due to the high demand for goods and shipping constraints. We
              ask you to choose Angels that have wishes you feel you can fulfill.
              If you can't find exact wishes, please find an alternative gift
              similar to the wish or a gift card to a store where Angels can
              purchase the wish later.
            </Typography>

            <br />

            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              How can I make changes to my sponsorship?
            </Typography>
            <Typography varient='p' component='p'>
              To make any changes regarding sponsorships, please contact us
              directly at{' '}
              <Link
                color='primary'
                variant='primary'
                href='mailto:angeltree@ufvsus.ca'
              >
                angeltree@ufvsus.ca
              </Link>.
            </Typography>

            <br />

            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              Where are the drop-off locations?
            </Typography>
            <Typography varient='p' component='p' gutterBottom>
              We have drop-off locations both in Abbotsford and Chilliwack at UFV campuses.
              In the Sponsorship Application, you will select your desired drop-off location.
              Please drop off the wishes at the location you selected in your application.
              To make any changes to your drop-off location,
              please contact us directly at <Link
                color='primary'
                variant='primary'
                href='mailto:angeltree@ufvsus.ca'
              >
                angeltree@ufvsus.ca
              </Link>. (Click <Button onClick={() => setOpenDialogDropoff(prev => !prev)} color='primary' size="small" variant="outlined">here</Button> for drop-off location & dates)
            </Typography>

            <br />

            <Typography
              gutterBottom
              variant='h5'
              fontWeight={'bold'}
              component='h5'
              color='primary'
            >
              I am unable to sponsor an Angel, but I would like to contribute to the program. Can I make a donation?
            </Typography>
            <Typography varient='p' component='p'>
              While sponsoring an Angel is the best way to support the program, we also accept donations to
              help support sponsorships for those Angels that don't get sponsored.
              If you would like to make a monetary contribution to the program, please contact us directly at {' '}
              <Link
                color='primary'
                variant='primary'
                href='mailto:angeltree@ufvsus.ca'
              >
                angeltree@ufvsus.ca
              </Link>.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <DropoffDetailDialog open={openDropoffDialog} setOpen={setOpenDialogDropoff} />
    </>
  );
};

export default FAQDialog;
