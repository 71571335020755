import React from 'react';
import { Fab, Link, Typography, Box, StyledEngineProvider } from '@mui/material';
import ScrollTop from '../../scroll/ScrollTop';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styles from '../../home/HomePage.module.css';

const Footer = (...props) => {
  return (
    <StyledEngineProvider injectFirst>
      <Box className='divider-pattern'>
        <img
          src='/assets/images/pattern.png'
          alt='pattern divider'
          className={styles.pattern_img}
        />
      </Box>

      <Box
        sx={{
          textAlign: 'center',
          color: 'white',
          position: 'relative',
          width: '100%',
          marginTop: '1rem',
          bottom: '2rem',
          height: '2rem',
        }}
      >
        <Box>
          <img
            src='/assets/images/sus_logo_footer.png'
            alt='SUS logo'
            style={{ maxHeight: '50px' }}
          />
        </Box>
        <Typography
          variant='body'
          component='p'
          fontSize='small'
          dangerouslySetInnerHTML={{
            __html: `Copyright &copy ${new Date().getFullYear()} UFV Student Union Society, All rights reserved.`,
          }}
          gutterBottom
        ></Typography>
        <Typography variant='body' component='p' fontSize='small'>
          <Link href='/legal/terms' target='_blank' color='secondary'>
            Terms and Conditions
          </Link>
        </Typography>

        <ScrollTop {...props}>
          <Fab color='secondary' size='small' aria-label='scroll back to top'>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </Box>
    </StyledEngineProvider>
  );
};

export default Footer;
