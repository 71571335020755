import React from 'react';
import './App.css';
import { Box } from '@mui/material';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './themes/theme';
import CssBaseline from '@mui/material/CssBaseline';

// Import Components
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/layout/navbar/Navbar';
import HomePage from './components/layout/home/HomePage';

// Parent
import ParentLayout from './components/parent/layout/ParentLayout';
import ParentForm from './components/parent/form/ParentForm';
import ParentFormResult from './components/parent/form/Result/ParentFormResult';
import ParentFAQ from './components/FAQ/ParentFAQ';
import PickupScreen from './components/parent/pickup/PickupScreen';
import PickupSearch from './components/parent/pickup/PickupSearch';

// Sponsor
import SponsorLayout from './components/sponsor/layout/SponsorLayout';
import SponsorForm from './components/sponsor/form/SponsorForm';
import SponsorFormResult from './components/sponsor/form/Result/SponsorFormResult';
import DropoffScreen from './components/sponsor/dropoff/DropoffScreen';
import SponsorFAQ from './components/FAQ/SponsorFAQ';
import DropoffSearch from './components/sponsor/dropoff/DropoffSearch';

// Term Layout
import TermLayout from './components/terms/layout/TermLayout';
import Terms from './components/terms/Terms';
import AngelTerms from './components/terms/AngelTerrms';
import SponsorTerms from './components/terms/SponsorTerms';
import Footer from './components/layout/customs/footer/Footer';

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box sx={{ minHeight: 'calc(100vh - 2rem)' }}>
          <CssBaseline />
          <Navbar />
          <Routes>
            <Route path='/' element={<HomePage />} />

            {/* Parent Routes */}
            <Route path='parent' element={<ParentLayout />}>
              <Route path='form' element={<ParentForm />} />
              <Route
                path='form/finish'
                element={<ParentFormResult />}
              />
              <Route path='faq' element={<ParentFAQ />} />
              <Route path='pickup' element={<PickupSearch />} />
              <Route
                path='pickup/:pickupId'
                element={<PickupScreen />}
              />
            </Route>

            {/* Sponsor Routes */}
            <Route path='sponsor' element={<SponsorLayout />}>
              <Route path='form' element={<SponsorForm />} />
              <Route
                path='form/finish'
                element={<SponsorFormResult />}
              />
              <Route path='faq' element={<SponsorFAQ />} />

              <Route path='dropoff' element={<DropoffSearch />} />
              <Route
                path='dropoff/:dropoffId'
                element={<DropoffScreen />}
              />
            </Route>

            <Route path='legal' element={<TermLayout />}>
              <Route path='terms' element={<Terms />} />
              <Route path='terms-angel' element={<AngelTerms />} />
              <Route path='terms-sponsor' element={<SponsorTerms />} />
            </Route>
          </Routes>
        </Box>
      </Router>
      <ToastContainer position='top-center'
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={true}
        pauseOnHover={true} />
      <Footer {...props} />

    </ThemeProvider>
  );
}

export default App;
