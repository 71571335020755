import { createTheme } from '@mui/material/styles';

export let theme = createTheme({
  palette: {
    primary: {
      main: '#CE4D4D',
    },
    secondary: {
      main: '#FDB91B',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
});
