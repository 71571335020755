import { useMemo } from "react"

const useTimeline = (startDate, endDate, longMonth = false, includeYear = false) => {
    return useMemo(() => {
        if (startDate === undefined || endDate === undefined) {
            return '';
        }

        let newStartDate = `${new Date(startDate).toLocaleString(undefined, { month: longMonth ? "long" : "short" })} ${new Date(startDate).getDate()}`;
        let newEndDate = `${new Date(endDate).toLocaleString(undefined, { month: longMonth ? "long" : "short" })} ${new Date(endDate).getDate()}`;

        return includeYear ? `${newStartDate} to ${newEndDate}, ${new Date(endDate).getFullYear()}` : `${newStartDate} - ${newEndDate}`;
    }, [startDate, endDate, longMonth, includeYear]);
}

export default useTimeline;