import React from 'react';
import { Box } from '@mui/material';

const FormContainer = ({ children }) => {
  return (
    <Box noValidate sx={{ color: 'white', marginTop: '2rem' }}>
      {children}
    </Box>
  );
};

export default FormContainer;
