import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';

const PickupDetailDialog = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        Pick-up Details
      </DialogTitle>
      <DialogContent>
        <DialogContentText component='div'>
          <Typography variant='p' component='p' gutterBottom>
            Please pick up your Angels wishes at the location you selected in your application. You will need to present your QR code or confirmation number at the time of pick-up. You can find your QR code or confirmation number in the confirmation email that you should have received after submitting your application.

            <br />
            <br />
            To make any changes to your pick-up location, don't hesitate to contact us directly at {' '}
            <Link
              color='primary'
              variant='primary'
              href='mailto:angeltree@ufvsus.ca'
            >
              angeltree@ufvsus.ca
            </Link>{' '}
            or call{' '}
            <Link color='primary' variant='primary' href='tel:6048644613'>
              604-864-4613
            </Link>
            .
          </Typography>

          <br />

          {/* Abbotsford pickup */}
          <Typography variant='p' component='p' fontWeight='bold'>Abbotsford Pick-up:</Typography>
          <Typography variant='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Location:</span> <Link color='primary' variant='primary' href='https://www.ufv.ca/maps/abbotsford-campus/'>Student Union Society, Building S, Room S1109</Link>
          </Typography>
          <Typography variant='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Dates:</span> Dec 10 & Dec 11
          </Typography>
          <Typography variant='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Hours:</span>: 9:00 am to 8:00 pm
          </Typography>

          <br />

          {/* Chilliwack pickup */}
          <Typography variant='p' component='p' fontWeight='bold'>Chilliwack Pick-up:</Typography>
          <Typography variant='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Location:</span> <Link color='primary' variant='primary' href='https://www.ufv.ca/maps/chilliwack-campus/'>CEP Campus, Building A, Room A1424</Link>
          </Typography>
          <Typography variant='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Dates:</span> Dec 10 & Dec 11
          </Typography>
          <Typography variant='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Hours:</span>: 9:00 am to 8:00 pm
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickupDetailDialog;
