import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Link,
} from '@mui/material';
import useTimeline from '../../../hooks/useTimeline';

const ParentRegistrationClosed = ({ open, setOpen, angelTreeTimeline }) => {
  let openPeriod = useTimeline(angelTreeTimeline?.registration?.startDate, angelTreeTimeline?.registration?.endDateCheck, true, true);

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>REGISTER AN ANGEL</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {new Date().getTime() <= new Date(angelTreeTimeline?.registration?.endDateCheck).getTime() ? `The Angel Registration period is from ${openPeriod}` : `We are sorry, but the Angel registration closed on ${new Date(angelTreeTimeline?.registration?.endDate).toLocaleString(undefined, { dateStyle: 'long' })}. Thank you for participating in the Angel Tree program this year`}. If
          you have any questions, please contact us at{' '}
          <Link color='primary' href='mailto:angeltree@ufvsus.ca'>
            angeltree@ufvsus.ca
          </Link>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParentRegistrationClosed;
