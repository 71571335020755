import React, { useState } from 'react';
import { Container, Box, Typography, IconButton, Link } from '@mui/material';
import styles from './SponsorForm.module.css';
import { StyledEngineProvider } from '@mui/material/styles';
import HeaderCard from '../../layout/customs/header/HeaderCard';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import HelpIcon from '@mui/icons-material/Help';
import FAQDialog from './FAQDialog';
import DropoffDetailDialog from '../DropoffDetailDialog';

const SponsorFormContainer = (props) => {
  const [openFAQS, setOpenFAQS] = useState(false);
  const [openDropoff, setOpenDropoff] = useState(false);

  return (
    <StyledEngineProvider injectFirst>
      <Container>
        <HeaderCard
          title='SPONSORING ANGEL(S)'
          icon={<ChildCareIcon sx={{ fontSize: 60 }} />}
        />
        <Box className={styles.mainBox}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='body1' component='p' gutterBottom>
              Sponsorships are open to UFV and local community members. This is
              an opportunity for you to support and make a difference to
              someone's holiday season by sponsoring one or more Angels.
            </Typography>
            <Box>
              <Link
                href='#'
                onClick={() => setOpenFAQS((prev) => !prev)}
                color='inherit'
              >
                Frequently Asked Questions
              </Link>
              <IconButton
                color='secondary'
                aria-label='help'
                sx={{ fontSize: '30px' }}
                onClick={() => setOpenFAQS((prev) => !prev)}
              >
                <HelpIcon fontSize='inherit' />
              </IconButton>
            </Box>
            <Box>
              <Link
                href='#'
                variant='body1'
                color='inherit'
                onClick={() => setOpenDropoff((prev) => !prev)}
              >
                Drop-off Detail
              </Link>
              <IconButton
                color='secondary'
                aria-label='help'
                sx={{ fontSize: '30px' }}
                onClick={() => setOpenDropoff((prev) => !prev)}
              >
                <LocationOnIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Container>
      <FAQDialog open={openFAQS} setOpen={setOpenFAQS} />
      <DropoffDetailDialog open={openDropoff} setOpen={setOpenDropoff} />
      {props.children}
    </StyledEngineProvider>
  );
};

export default SponsorFormContainer;