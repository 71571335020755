import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';

import styles from '../SponsorForm.module.css';
import SponsorFormContainer from '../SponsorFormContainer';
import Spinner from '../../../layout/Spinner';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetSponsorFormValues } from '../../sponsorFormSlice';
import QRCode from 'qrcode';
import FileSaver from 'file-saver';
import { selectConfigByKey } from '../../../../custom-slices/configurationSlice';

function AlertDialog({ open, setOpen, dataFromServer, src, qrCodeEnabled }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // download QR code
  const downloadQRCode = () => {
    const image = document.getElementById('qrCodeEl');
    const imgPath = image.getAttribute('src');
    let fileName = 'dropoff_qrcode';
    FileSaver.saveAs(imgPath, fileName);
  };

  const handleClose = () => {
    dispatch(resetSponsorFormValues());
    setOpen(false);
    navigate('/');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {dataFromServer?.dropoffId === undefined ? (
        <>
          <DialogTitle id='alert-dialog-title'>
            Have you selected the Angel(s) this year?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Please click <Link href='/sponsor/form'>here</Link> to sponsor
              Angel(s)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' autoFocus>
              Done
            </Button>
          </DialogActions>{' '}
        </>
      ) : (
        <>
          <DialogTitle id='alert-dialog-title' sx={{ textAlign: 'center' }}>
            Thank you! Your Angel sponsorship has been successfully submitted.
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              component='div'
              className={styles.diaglogContent}
            >
              {qrCodeEnabled && (src === '' ? (
                <Spinner color='#CE4D4D' />
              ) : (
                <>
                  Please save or take a screenshot of the QR below for seamless
                  drop-off.
                  <Box sx={{ marginTop: '2rem' }}>
                    <Box component='div'>
                      <img
                        id='qrCodeEl'
                        src={src}
                        alt='QR code for dropoff'
                        className={styles.QRimage}
                      />
                    </Box>

                    <Box component='div'>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => downloadQRCode()}
                      >
                        Save a Copy
                      </Button>
                    </Box>
                  </Box>
                </>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' autoFocus>
              Done
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

const SponsorFormResult = () => {
  const { width, height } = useWindowSize();

  const [open, setOpen] = useState(true);
  const [src, setSrc] = useState('');

  const configsState = useSelector(state => state.configuration.configs);

  const qrCodeEnabled = selectConfigByKey(configsState, 'enable-qr-code-after-form-submission');

  const sponsorForm = useSelector((state) => state.sponsor.sponsorForm);

  const { submitSuccess, dataFromServer } = sponsorForm;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    const showQRCode = () => {
      const API_URL = process.env.REACT_APP_API_URL;
      let URL = '';

      if (API_URL === 'http://localhost:5500') {
        URL = 'http://localhost:3000/sponsor/dropoff'
      } else if (API_URL === 'https://angeltree-server-test.ufvsus.ca') {
        URL = 'https://angeltree-test.ufvsus.ca/sponsor/dropoff'
      } else {
        URL = 'https://angeltree.ufvsus.ca/sponsor/dropoff';
      }

      QRCode.toDataURL(`${URL}/${dataFromServer?.dropoffId}`, {
        errorCorrectionLevel: 'H',
      })
        .then((url) => setSrc(url))
        .catch((err) => {
          console.error(err);
        });
    };

    if (qrCodeEnabled) {
      showQRCode();
    }

    return () => {
      window.removeEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [submitSuccess, dataFromServer, qrCodeEnabled]);

  return (
    <SponsorFormContainer>
      <Confetti width={width} height={height} />
      <AlertDialog
        open={open}
        setOpen={setOpen}
        dataFromServer={dataFromServer}
        src={src}
        qrCodeEnabled={qrCodeEnabled}
      />
    </SponsorFormContainer>
  );
};

export default SponsorFormResult;
