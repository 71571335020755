import React, { useMemo, useState } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import { Box, Typography, Link, Button } from '@mui/material';
import styles from './HomePage.module.css';
import DropoffDetailDialog from '../../sponsor/DropoffDetailDialog';
import ParentRegistrationClosed from './ParentRegistrationClosed';
import PickupDetailDialog from '../../parent/PickupDetailDialog';

import { useSelector } from 'react-redux';
import { selectConfigByKey } from '../../../custom-slices/configurationSlice';
import useTimeline from '../../../hooks/useTimeline';

const cardStyles = {
  backgroundColor: 'rgba(0,0,0, 0.2)',
  color: '#fff',
  backdropFilter: 'blur(1rem)',
  WebkitBackdropFilter: 'blur(1rem)',
  boxShadow: '10px 10px 10px rgba(30, 30, 30, 0.1)',
  borderRadius: '10px',
};

const arrowRightStyles = {
  color: 'rgba(0,0,0, 0.2)',
  WebkitBackdropFilter: 'blur(1rem)',
  boxShadow: '10px 10px 10px rgba(30, 30, 30, 0.1)',
  borderRight: '7px solid  rgba(0,0,0, 0.2)',
};

const Section3 = () => {
  const configsState = useSelector(state => state.configuration.configs);

  const angelTreeTimeline = selectConfigByKey(configsState, 'angel-tree-timeline');

  const [openDropoffDialog, setOpenDropoffDialog] = useState(false);
  const [openPickupDialog, setOpenPickupDialog] = useState(false);
  const [openRegistrationDialog, setOpenRegistrationDialog] = useState(false);

  let registrationPeriod = useTimeline(angelTreeTimeline?.registration?.startDate, angelTreeTimeline?.registration?.endDate);
  let sponsorshipPeriod = useTimeline(angelTreeTimeline?.sponsorship?.startDate, angelTreeTimeline?.sponsorship?.endDate);
  let dropoffPeriod = useTimeline(angelTreeTimeline?.dropoff?.startDate, angelTreeTimeline?.dropoff?.endDate);
  let pickupPeriod = useTimeline(angelTreeTimeline?.pickup?.startDate, angelTreeTimeline?.pickup?.endDate);

  const timeLineElements = useMemo(() => {
    return [
      {
        id: 1,
        title: (
          <Link underline='none' href='/parent/form' color='secondary'>
            REGISTRATION FOR ANGELS
          </Link>
        ),
        description: (
          <div>
            <p>
              UFV students with children under 16 years are encouraged to apply during this period.
              If you have any questions, please get in touch with us at <a style={{ color: 'white' }} href='mailto:angeltree@ufvsus.ca'>angeltree@ufvsus.ca</a>.
            </p>
            <p>
              <Button
                variant='outlined'
                color='secondary'
                size='string'
                href='/parent/form'
              >
                Register Now
              </Button>
            </p>
          </div>
        ),
        icon: <BorderColorIcon />,
        date: registrationPeriod,
        current: angelTreeTimeline !== null ? (new Date() >= new Date(angelTreeTimeline.registration.startDate).getTime()) : true
      },

      {
        id: 2,
        title: (
          <Link underline='none' href='/sponsor/form' color='secondary'>
            FULFILLING WISHES
          </Link>
        ),
        description: (
          <div>
            <p>
              Angel Sponsorships are open to all UFV and external communities.
              Take the opportunity to make a difference to someone's holiday
              season by sponsoring an Angel or Angels.
            </p>

            <p>
              <Button
                variant='outlined'
                color='secondary'
                size='string'
                href='/sponsor/form'
              >
                Become a sponsor
              </Button>
            </p>
          </div>
        ),
        icon: <ChildCareIcon />,
        date: sponsorshipPeriod,
        current: angelTreeTimeline !== null ? (new Date() >= new Date(angelTreeTimeline.sponsorship.startDate).getTime()) : true
      },

      {
        id: 3,
        title: (
          <Link underline='none' href='/sponsor/dropoff' color='secondary'>
            WISH DROP-OFF
          </Link>
        ),
        description: (
          <div>
            <p>
              During this period, sponsors will drop off Angel's wishes at one of our drop-off locations.
              SUS team members will then sort and prep all wishes for Angels.
            </p>
            <p>
              {' '}
              <Button
                variant='outlined'
                color='secondary'
                size='string'
                onClick={() => setOpenDropoffDialog((prev) => !prev)}
              >
                More
              </Button>
            </p>
          </div>
        ),
        icon: <DeliveryDiningIcon />,
        date: dropoffPeriod,
        current: angelTreeTimeline !== null ? (new Date() >= new Date(angelTreeTimeline.dropoff.startDate).getTime()) : true
      },
      {
        id: 4,
        title: (
          <Link underline='none' href='/parent/pickup' color='secondary'>
            ANGEL PICK-UP
          </Link>
        ),
        description: (
          <div>
            <p>
              We will be offering multiple days for pick-up to create a seamless,
              hassle-free opportunity for all Angel families.
            </p>
            <p>
              <Button
                variant='outlined'
                color='secondary'
                size='string'
                onClick={() => setOpenPickupDialog((prev) => !prev)}
              >
                More
              </Button>
            </p>
          </div>
        ),
        icon: <CardGiftcardIcon />,
        date: pickupPeriod,
        current: angelTreeTimeline !== null ? (new Date() >= new Date(angelTreeTimeline.pickup.startDate).getTime()) : true
      },
      {
        id: 5,
        title: 'HAPPY HOLIDAYS',
        description: 'Celebrating yet another successful Angel Tree!',
        icon: <AcUnitIcon />,
        date: '',
        current: angelTreeTimeline !== null ? (new Date() >= new Date(angelTreeTimeline.pickup.startDate).getTime()) : true
      },
    ];
  }, [angelTreeTimeline, registrationPeriod, sponsorshipPeriod, dropoffPeriod, pickupPeriod]);

  return (
    <Box sx={{ marginBottom: 8 }}>
      <Box className={styles.leftText}>
        <img
          src='/assets/images/calendar_star.png'
          alt='Calendar star'
          className={`homepage-images ${styles.image}`}
        />
        <Box sx={{ marginBottom: 8 }}>
          <Typography
            variant='h4'
            component='h4'
            sx={{ fontWeight: 'bold', marginTop: '2rem', marginBottom: '2rem' }}
            className={styles.headline}
          >
            {new Date().getFullYear()} PROGRAM TIMELINE
          </Typography>

          <Typography variant='p' component='p'>
            <Link href='/parent/faq' target='_blank' color='secondary'>
              Angel
            </Link>{' '}
            |{' '}
            <Link href='/sponsor/faq' target='_blank' color='secondary'>
              Sponsor
            </Link>{' '}
            's Frequently Asked Questions
          </Typography>
        </Box>
      </Box>
      <VerticalTimeline lineColor='#FDB91B'>
        {timeLineElements.map((element) => {
          return (
            <VerticalTimelineElement
              key={element.id}
              date={element.date}
              contentStyle={cardStyles}
              contentArrowStyle={arrowRightStyles}
              iconStyle={
                element.current
                  ? {
                    background: '#CE4D4D',
                    color: '#fff',
                  }
                  : {
                    background: '#026b64',
                    color: '#fff',
                  }
              }
              icon={element.icon}
              dateClassName={styles.date}
            >
              <h3 className='vertical-timeline-element-title'>
                {element.title}
              </h3>
              <div>{element.description}</div>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
      {openDropoffDialog && (
        <DropoffDetailDialog
          open={openDropoffDialog}
          setOpen={setOpenDropoffDialog}
        />
      )}

      {openPickupDialog && (
        <PickupDetailDialog
          open={openPickupDialog}
          setOpen={setOpenPickupDialog}
        />
      )}

      {openRegistrationDialog && (
        <ParentRegistrationClosed
          open={openRegistrationDialog}
          setOpen={setOpenRegistrationDialog}
          angelTreeTimeline={angelTreeTimeline}
        />
      )}
    </Box>
  );
};

export default Section3;
