import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
} from '@mui/material';
import styles from '../SponsorForm.module.css';
import EditIcon from '@mui/icons-material/Edit';
import GlassCard from '../../../customs/GlassCard';
import ChildCard from '../ChildCard';

// Import useSelector and useDispatch to manage the global state
import { useSelector } from 'react-redux';

const sponsorEntryFields = [
  'Full Name',
  'Email Address',
  'Phone Number',
  'What describes you the best?',
  'Drop-off Location',
];

const PreviewInfo = ({ setStep }) => {
  const sponsorState = useSelector((state) => state.sponsor.sponsorForm);
  const { selectedChildren, values } = sponsorState;

  //   Create entries for sponsor info => return array. For example, [['name', 'John Doe'],...[]]
  const sponsorEntries = useMemo(
    () =>
      Object.entries(values).filter((entry) => {
        return entry[0] !== 'selectedChildrenIds';
      }),
    [values]
  );

  sponsorEntries.forEach((entry, index) => {
    entry[0] = sponsorEntryFields[index];

    if (entry[1] === 'Abbotsford') entry[1] = 'Abbotsford: Abby Campus, S Building';
    else if (entry[1] === 'Chilliwack')
      entry[1] = 'Chilliwack: CEP Campus, A Building';
  });

  //   Change step
  const handleStep = (step) => {
    setStep(step);
  };

  const handleToggle = () => {
    return;
  };

  return (
    <>
      <Box>
        <GlassCard sx={{ marginTop: '2rem' }}>
          <Box className={styles.editable}>
            <Typography variant='h5' component='h5'>
              Your selected Angel(s)
            </Typography>
            <IconButton
              aria-label='edit'
              color='secondary'
              onClick={() => handleStep(0)}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </GlassCard>

        {selectedChildren.length > 0 && (
          <Box className={styles.angelContainerPreview}>
            {selectedChildren.map((child) => (
              <ChildCard
                key={child.childId}
                child={child}
                onToggle={handleToggle}
              />
            ))}
          </Box>
        )}
      </Box>

      <Box>
        <GlassCard sx={{ marginTop: 5, width: '100%' }}>
          <Box className={styles.editable}>
            <Typography variant='h5' component='h5'>
              Your information
            </Typography>
            <IconButton
              aria-label='edit'
              color='secondary'
              onClick={() => handleStep(1)}
            >
              <EditIcon />
            </IconButton>
          </Box>

          <RenderTable entries={sponsorEntries} />
        </GlassCard>
      </Box>
    </>
  );
};

const RenderTable = ({ entries }) => (
  <TableContainer component='div'>
    <Table sx={{ minWidth: 250 }} aria-label='simple table'>
      <TableBody>
        {entries.map((entry, index) => (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell component='th' scope='row' sx={{ color: 'white' }}>
              {entry[0]}
            </TableCell>
            <TableCell
              align='right'
              sx={{ color: '#FDB91B', fontWeight: 'bold' }}
            >
              {entry[1].toString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default PreviewInfo;
