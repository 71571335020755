import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';

import styles from '../ParentForm.module.css';
import Spinner from '../../../layout/Spinner';

import { useSelector, useDispatch } from 'react-redux';
import { resetParentFormValues } from '../../parentFormSlice';
import { useNavigate } from 'react-router-dom';
// import QRCode from 'qrcode.react';
import QRCode from 'qrcode';
import FileSaver from 'file-saver';
import ParentFormContainer from '../ParentFormContainer';
import { selectConfigByKey } from '../../../../custom-slices/configurationSlice';

function AlertDialog({ open, setOpen, data, src, qrCodeEnabled }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // download QR code
  const downloadQRCode = () => {
    const image = document.getElementById('qrCodeEl');
    const imgPath = image.getAttribute('src');
    let fileName = 'pickup_qrcode';
    FileSaver.saveAs(imgPath, fileName);
  };

  const handleClose = () => {
    dispatch(resetParentFormValues());
    setOpen(false);
    navigate('/');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {data?.pickupId === undefined ? (
        <>
          <DialogTitle id='alert-dialog-title'>
            Have you submitted your Angel registration form?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Please click <Link href='/parent/form'>here</Link> to register
              your angel(s)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleClose} autoFocus>
              Done
            </Button>
          </DialogActions>{' '}
        </>
      ) : (
        <>
          <DialogTitle id='alert-dialog-title' sx={{ textAlign: 'center' }}>
            Congrats! Your Angel Application has been successfully submitted for review.
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-description'
              component='div'
              className={styles.diaglogContent}
            >
              A SUS team member will be in contact shortly to discuss your application status for this year.
              {qrCodeEnabled && (src === '' ? (
                <Spinner color='#CE4D4D' />
              ) : (
                <>
                  In the meantime, can you please save or take a screenshot of the QR below for seamless pick-up.
                  <Box sx={{ marginTop: '2rem' }}>
                    <Box component='div'>
                      <img
                        id='qrCodeEl'
                        src={src}
                        alt='QR code for pickup'
                        className={styles.QRimage}
                      />
                    </Box>

                    <Box component='div'>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => downloadQRCode()}
                      >
                        Save a Copy
                      </Button>
                    </Box>
                  </Box>
                </>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' autoFocus>
              Done
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

const ParentFormResult = () => {
  const { width, height } = useWindowSize();
  const [open, setOpen] = useState(true);
  const [src, setSrc] = useState('');

  const configsState = useSelector(state => state.configuration.configs);

  const qrCodeEnabled = selectConfigByKey(configsState, 'enable-qr-code-after-form-submission');

  const form = useSelector((state) => state.parent.parentForm);
  const { success, data } = form;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    const showQRCode = () => {
      const API_URL = process.env.REACT_APP_API_URL;
      let URL = '';
      if (API_URL === 'http://localhost:5500') {
        URL = 'http://localhost:3000/parent/pickup'
      } else if (API_URL === 'https://angeltree-server-test.ufvsus.ca') {
        URL = 'https://angeltree-test.ufvsus.ca/parent/pickup'
      } else {
        URL = 'https://angeltree.ufvsus.ca/parent/pickup';
      }

      QRCode.toDataURL(`${URL}/${data?.pickupId}`, {
        errorCorrectionLevel: 'H',
      })
        .then((url) => setSrc(url))
        .catch((err) => {
          console.error(err);
        });
    };

    if (qrCodeEnabled) {
      showQRCode();
    }

    return () => {
      window.removeEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [success, data, qrCodeEnabled]);

  return (
    <ParentFormContainer>
      <Confetti width={width} height={height} />

      <AlertDialog
        open={open}
        setOpen={setOpen}
        data={data}
        src={src}
        qrCodeEnabled={qrCodeEnabled}
      />
    </ParentFormContainer>
  );
};

export default ParentFormResult;
