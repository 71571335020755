import React, { useState, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styles from '../SponsorForm.module.css';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import SelectedChildrenDialog from './SelectedChildrenDialog';

import { useSelector, useDispatch } from 'react-redux';
import {
  checkSponsorship,
  fetchChildren,
  filterSelectedChildren,
  setSponsorFormValues,
} from '../../sponsorFormSlice';
import { toast } from 'react-toastify';

const AlertNav = ({
  selectedCount,
  selectedChildrenIds,
  setSelectedChildrenIds,
  nextStep,
  onToggle,
}) => {
  const sponsorState = useSelector((state) => state.sponsor.sponsorForm);
  const { children, checkingLoading } = sponsorState;
  const dispatch = useDispatch();

  const [stickyClass, setStickyClass] = useState('');
  const [open, setOpen] = useState(false);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 600 ? setStickyClass('sticky_nav') : setStickyClass('');
    }
  };

  useEffect(() => {
    // Filter out sponsored children after fetching children again
    dispatch(filterSelectedChildren());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const goNextStep = () => {
    const childrenObj = {
      selectedChildrenIds: Array.from(selectedChildrenIds),
    };
    dispatch(checkSponsorship(childrenObj))
      .unwrap()
      .then(() => {
        nextStep();
      })
      .catch((err) => {
        toast.error(err.message);
        const childrenObj = {
          selectedChildrenIds: err?.data?.newSelectedChildrenIds,
        };
        dispatch(setSponsorFormValues(childrenObj));
        setSelectedChildrenIds(new Set(err?.data?.newSelectedChildrenIds));
        dispatch(fetchChildren());
      });
  };

  return (
    <header className={stickyClass && styles.sticky_nav}>
      <Box className={styles.alertNav}>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          <Box>
            <FaceRetouchingNaturalIcon fontSize='large' />
          </Box>
          <Box>
            You have selected{' '}
            <span style={{ fontWeight: 'bold' }}>{selectedCount}</span> Angel(s)
          </Box>
          <Box>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent='center'
              alignItems='center'
            >
              <Button
                variant='outlined'
                color='info'
                startIcon={<OpenInNewIcon />}
                onClick={() => setOpen((prev) => !prev)}
              >
                Show
              </Button>
              <LoadingButton
                variant='contained'
                color='secondary'
                endIcon={<NavigateNextIcon />}
                onClick={goNextStep}
                loading={checkingLoading}
              >
                Next
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <SelectedChildrenDialog
        open={open}
        setOpen={setOpen}
        selectedChildrenIds={selectedChildrenIds}
        onToggle={onToggle}
      />
    </header>
  );
};

export default AlertNav;
