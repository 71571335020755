import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import styles from './ParentPickup.module.css';
import { CustomTextField } from '../../customs/CustomTextField';
import ParentPickupHeader from './ParentPickupHeader';
import GlassCard from '../../customs/GlassCard';

import { useForm } from 'react-hook-form';

const PickupSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();

  const onSubmit = (value) => {
    navigate(`${location.pathname}/${value.pickupId.trim()}`);
  };

  const onEnterSubmit = (pickupId) => {
    navigate(`${location.pathname}/${pickupId.trim()}`);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ParentPickupHeader />

      <Container className={styles.searchContainer}>
        <GlassCard className={styles.searchCard}>
          <Typography
            variant='h6'
            component='h6'
            textAlign='center'
            sx={{ marginBottom: '2rem' }}
          >
            Pick-up Information Search
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            justifyContent='center'
            alignItems='center'
            spacing={{ xs: 2, sm: 2, md: 4 }}
            sx={{ marginBottom: '1rem' }}
          >
            <Box width='100%'>
              <CustomTextField
                label='Confirmation ID'
                type='text'
                variant='outlined'
                focusColor='#FDB91B'
                fullWidth
                {...register('pickupId', { required: true })}
                helperText={
                  errors?.pickupId?.type === 'required' &&
                  'Angel Confirmation ID required'
                }
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.code === 'Enter')
                    onEnterSubmit(getValues('pickupId'));
                }}
              />
            </Box>
            <Box>
              <Button
                type='submit'
                color='secondary'
                size='large'
                variant='contained'
                onClick={handleSubmit(onSubmit)}
              >
                Search
              </Button>
            </Box>
          </Stack>
        </GlassCard>
      </Container>
    </StyledEngineProvider>
  );
};

export default PickupSearch;
