import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const override = {
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  marginTop: '2rem',
  marginBottom: '2rem',
  borderColor: 'yellow',
}
  


const Spinner = (props) => {
  return (
    <BeatLoader
      size={45}
      color={props?.color ? props.color : '#F8E71C'}
      cssOverride={override}
    />
  );
};

export default Spinner;
