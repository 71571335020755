import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Container,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { StyledEngineProvider } from '@mui/material/styles';
import styles from './SponsorDropoff.module.css';
import GlassCard from '../../customs/GlassCard';
import Spinner from '../../layout/Spinner';
import { CustomTextField } from '../../customs/CustomTextField';
import ChildCard from './ChildCard';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
import SponsorDropoffHeader from './SponsorDropoffHeader';
import { getSponsorDropoffInfo, updateSponsorDropOff } from '../sponsorInfoSlice';

import { toast } from 'react-toastify';

const DropoffScreen = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const dispatch = useDispatch();

  const { dropoffId } = useParams();
  const sponsorInfo = useSelector((state) => state.sponsor.sponsorInfo);
  const {
    sponsorEntries,
    childrenEntries,
    dropoff,
    loading,
    error,
    updateLoading,
  } = sponsorInfo;

  useEffect(() => {
    dispatch(getSponsorDropoffInfo(dropoffId));
  }, [dispatch, dropoffId]);

  const onSubmit = (code) => {
    const data = { ...code, dropoffId };
    dispatch(updateSponsorDropOff(data))
      .unwrap()
      .then((message) => {
        dispatch(getSponsorDropoffInfo(dropoffId));
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const RenderDropoffInfo = () => {
    return (
      <Container>
        <GlassCard
          sx={{ marginTop: '2rem', marginBottom: '2rem', width: '100%' }}
        >
          <Box>
            <Typography variant='h5' component='h5'>
              Your information
            </Typography>
          </Box>

          <RenderTable entries={sponsorEntries} />
        </GlassCard>

        {childrenEntries.length > 0 && (
          <>
            <GlassCard
              sx={{ marginTop: '2rem', marginBottom: '2rem', width: '100%' }}
            >
              <Box>
                <Typography variant='h5' component='h5'>
                  Your selected Angel(s)
                </Typography>
              </Box>
            </GlassCard>

            <Box>
              <Box className={styles.angelContainerPreview}>
                {childrenEntries.map((child) => (
                  <ChildCard key={child.childId} child={child} />
                ))}
              </Box>
            </Box>
          </>
        )}

        {dropoff ? (
          <></>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.adminTextBox}
          >
            <CustomTextField
              label='Admin Code'
              type='text'
              variant='outlined'
              focusColor='#FDB91B'
              {...register('confirmCode', { required: true })}
              helperText={
                errors?.confirmCode?.type === 'required' &&
                'Please input the admin code'
              }
            />
            <LoadingButton
              type='submit'
              color='secondary'
              size='large'
              variant='contained'
              loading={updateLoading}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Container>
    );
  };

  if (error)
    return (
      <StyledEngineProvider injectFirst>
        <SponsorDropoffHeader />
        <Container
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
        >
          <GlassCard sx={{ width: 'fit-content' }}>
            <Typography variant='h5' component='h5' color='yellow'>
              {error}
            </Typography>
          </GlassCard>
        </Container>
        <Box sx={{ marginTop: '2rem' }} textAlign='center'>
          <Link href='/sponsor/dropoff' color='secondary'>
            Search another one?
          </Link>
        </Box>
      </StyledEngineProvider>
    );

  return (
    <StyledEngineProvider injectFirst>
      <SponsorDropoffHeader />
      {loading ? <Spinner /> : <RenderDropoffInfo />}
      <Box sx={{ marginTop: '2rem' }} textAlign='center'>
        <Link href='/sponsor/dropoff' color='secondary'>
          Search another one?
        </Link>
      </Box>
    </StyledEngineProvider>
  );
};

const RenderTable = ({ entries }) => (
  <TableContainer component='div'>
    <Table sx={{ minWidth: 250 }} aria-label='simple table'>
      <TableBody>
        {entries.map((entry, index) => (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell component='th' scope='row' sx={{ color: 'white' }}>
              {entry[0]}
            </TableCell>
            <TableCell
              align='right'
              sx={{ color: '#FDB91B', fontWeight: 'bold' }}
            >
              {entry[1].toString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default DropoffScreen;
