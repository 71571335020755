import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Container,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import GlassCard from '../../customs/GlassCard';
import { CustomTextField } from '../../customs/CustomTextField';

import styles from './ParentPickup.module.css';
import { StyledEngineProvider } from '@mui/material/styles';
import Spinner from '../../layout/Spinner';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
import ParentPickupHeader from './ParentPickupHeader';
import { getParentPickupInfo, updateParentPickup } from '../parentInfoSlice';

import { toast } from 'react-toastify';

const PickupScreen = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const dispatch = useDispatch();

  const { pickupId } = useParams();

  useEffect(() => {
    dispatch(getParentPickupInfo(pickupId));
  }, [dispatch, pickupId]);

  const parentInfo = useSelector((state) => state.parent.parentInfo);
  const {
    parentEntries,
    childrenEntries,
    approved,
    loading,
    error,
    updateLoading,
    pickup,
  } = parentInfo;

  const onSubmit = (code) => {
    const data = { ...code, pickupId };
    dispatch(updateParentPickup(data))
      .unwrap()
      .then((message) => {
        dispatch(getParentPickupInfo(pickupId));
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const RenderPickupInfo = () => {
    return (
      <Container>
        <GlassCard sx={{ marginTop: 5, width: '100%' }}>
          <Box>
            <Typography variant='h5' component='h5'>
              Your information
            </Typography>
          </Box>

          <RenderTable entries={parentEntries} />
        </GlassCard>

        {childrenEntries.length > 0 && (
          <>
            <GlassCard
              sx={{ marginTop: '2rem', marginBottom: '2rem', width: '100%' }}
            >
              <Box>
                <Typography variant='h5' component='h5'>
                  Your Angel(s)
                </Typography>
              </Box>
            </GlassCard>
            {childrenEntries.map((childEntries, index) => (
              <GlassCard key={index} sx={{ marginTop: 5, width: '100%' }}>
                <Box>
                  <Typography variant='h5' component='h5'>
                    Angel {index + 1} 's information
                  </Typography>
                </Box>

                <RenderTable entries={childEntries} />
              </GlassCard>
            ))}
          </>
        )}

        {pickup ? (
          <></>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.adminTextBox}
          >
            <CustomTextField
              label='Admin Code'
              type='text'
              variant='outlined'
              focusColor='#EF9B14'
              {...register('confirmCode', { required: true })}
              helperText={
                errors?.confirmCode?.type === 'required' &&
                'Please input the admin code'
              }
            />

            <LoadingButton
              type='submit'
              color='secondary'
              size='large'
              variant='contained'
              loading={updateLoading}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Container>
    );
  };

  if (approved === 'No')
    return (
      <StyledEngineProvider injectFirst>
        <ParentPickupHeader />
        <Container
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
        >
          <GlassCard sx={{ width: 'fit-content' }}>
            <Typography variant='h5' component='h5' color='yellow'>
              We are currently reviewing your application. For further assistance, please reach out to us at {' '}
              <Link color='inherit' href='mailto:angeltree@ufvsus.ca'>
                angeltree@ufvsus.ca
              </Link>
            </Typography>
          </GlassCard>
        </Container>
        <Box sx={{ marginTop: '2rem' }} textAlign='center'>
          <Link href='/parent/pickup' color='secondary'>
            Search another one?
          </Link>
        </Box>
      </StyledEngineProvider>
    );

  if (error)
    return (
      <StyledEngineProvider injectFirst>
        <ParentPickupHeader />
        <Container
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
        >
          <GlassCard sx={{ width: 'fit-content' }}>
            <Typography variant='h5' component='h5' color='yellow'>
              {error}
            </Typography>
          </GlassCard>
        </Container>
        <Box sx={{ marginTop: '2rem' }} textAlign='center'>
          <Link href='/parent/pickup' color='secondary'>
            Search another one?
          </Link>
        </Box>
      </StyledEngineProvider>
    );

  return (
    <StyledEngineProvider injectFirst>
      <ParentPickupHeader />
      {loading ? <Spinner /> : <RenderPickupInfo />}
      <Box sx={{ marginTop: '2rem' }} textAlign='center'>
        <Link href='/parent/pickup' color='secondary'>
          Search another one?
        </Link>
      </Box>
    </StyledEngineProvider>
  );
};

const RenderTable = ({ entries }) => (
  <TableContainer component='div'>
    <Table sx={{ minWidth: 250 }} aria-label='simple table'>
      <TableBody>
        {entries.map((entry, index) => (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell component='th' scope='row' sx={{ color: 'white' }}>
              {entry[0]}
            </TableCell>
            <TableCell
              align='right'
              sx={{ color: '#FDB91B', fontWeight: 'bold' }}
            >
              {entry[1].toString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default PickupScreen;
