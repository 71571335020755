import { Select, styled } from '@mui/material';
import { outlinedInputClasses } from '@mui/material';

export const CustomSelectField = styled(Select)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: white;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: #EF9B14;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: #EF9B14;
  },

  .MuiSelect-iconOutlined {
    color: white
  }
 
`);
