import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GlassCard from '../../../customs/GlassCard';

import styles from '../ParentForm.module.css';

// Import useSelector and useDispatch to manage the global state
import { useSelector } from 'react-redux';

const parentEntryFields = [
  'Full Name',
  'Student ID',
  'Email Address',
  'Phone Number',
  'Pick-up Location',
  'Additional Person',
  'SMS Update'
];

const childEntryFields = ['First Name', 'Age', 'Gender', 'Wish'];

const PreviewInfo = ({ setStep }) => {
  const formValues = useSelector((state) => state.parent.parentForm.values);

  // Create entries for parent info => return array. For example, [['name', 'John Doe'],...[]]
  const parentEntries = useMemo(
    () =>
      Object.entries(formValues).filter((entry) => {
        return entry[0] !== 'children';
      }),
    [formValues]
  );

  parentEntries.forEach((entry, index) => {
    entry[0] = parentEntryFields[index];
  });

  if (parentEntries[4][1] === 'Abbotsford') {
    parentEntries[4][1] = 'Abbotsford: Abby Campus, S Building';
  }
  else if (parentEntries[4][1] === 'Chilliwack') {
    parentEntries[4][1] = 'Chilliwack: CEP Campus, A Building';
  }

  if (parentEntries[5][1] === '') {
    parentEntries[5][1] = 'None';
  }
  parentEntries[6][1] = parentEntries[6][1] ? 'Yes' : 'No';

  // Create entries for kids

  const childrenEntries = [];
  const children = formValues.children;
  children.forEach((child) => {
    const childEntry = Object.entries(child);
    const tempArr = [];

    // Change the positions of entries
    tempArr.push(childEntry[3]);
    tempArr.push(childEntry[2]);
    tempArr.push(childEntry[1]);
    tempArr.push(childEntry[0]);

    tempArr.forEach((entry, index) => {
      entry[0] = childEntryFields[index];
    });

    childrenEntries.push(tempArr);
  });

  //   Change step
  const handleStep = (step) => {
    setStep(step);
  };

  return (
    <>
      <GlassCard sx={{ marginTop: 5, width: '100%' }}>
        <Box className={styles.editable}>
          <Typography variant='h5' component='h5'>
            Your information
          </Typography>
          <IconButton
            aria-label='edit'
            color='secondary'
            onClick={() => handleStep(0)}
          >
            <EditIcon />
          </IconButton>
        </Box>

        <RenderTable entries={parentEntries} />
      </GlassCard>
      {childrenEntries.map((childEntries, index) => (
        <GlassCard key={index} sx={{ marginTop: 5, width: '100%' }}>
          <Box className={styles.editable}>
            <Typography variant='h5' component='h5'>
              Angel {index + 1} 's information
            </Typography>
            <IconButton
              aria-label='edit'
              color='secondary'
              onClick={() => handleStep(1)}
            >
              <EditIcon />
            </IconButton>
          </Box>

          <RenderTable entries={childEntries} />
        </GlassCard>
      ))}
    </>
  );
};

const RenderTable = ({ entries }) => (
  <TableContainer component='div'>
    <Table sx={{ minWidth: 250 }} aria-label='simple table'>
      <TableBody>
        {entries.map((entry, index) => (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell component='th' scope='row' sx={{ color: 'white' }}>
              {entry[0]}
            </TableCell>
            <TableCell
              align='right'
              sx={{ color: '#FDB91B', fontWeight: 'bold' }}
            >
              {entry[1].toString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default PreviewInfo;
