import React, { useState } from 'react';
import { Container, Box, Typography, Link, IconButton } from '@mui/material';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import HelpIcon from '@mui/icons-material/Help';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styles from './SponsorDropoff.module.css';
import { StyledEngineProvider } from '@mui/material/styles';
import HeaderCard from '../../layout/customs/header/HeaderCard';
import DropoffDetailDialog from '../DropoffDetailDialog';

const SponsorDropoffHeader = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledEngineProvider injectFirst>
      <Container>
        <HeaderCard
          title='DROP OFF INFORMATION'
          icon={<DeliveryDiningIcon sx={{ fontSize: 60 }} />}
        />
        <Box className={styles.mainBox}>
          <Box textAlign='center'>
            <Typography variant='body1' component='p'>
              During this period, Sponsors will drop off Angel's wishes at one
              of our drop-off locations. SUS team members will then sort and
              prep all wishes for Angels.
            </Typography>
            <Typography variant='body1' component='p'>
              <Link
                href='/sponsor/faq'
                target='_blank'
                variant='body1'
                color='inherit'
              >
                Frequently Asked Questions
              </Link>
              <IconButton
                color='secondary'
                aria-label='help'
                sx={{ fontSize: '30px' }}
                href='/sponsor/faq'
                target='_blank'
              >
                <HelpIcon fontSize='inherit' />
              </IconButton>
            </Typography>

            <Typography variant='body1' component='p'>
              <Link
                href='#'
                variant='body1'
                color='inherit'
                onClick={() => setOpen((prev) => !prev)}
              >
                Drop-off Detail
              </Link>
              <IconButton
                color='secondary'
                aria-label='help'
                sx={{ fontSize: '30px' }}
                onClick={() => setOpen((prev) => !prev)}
              >
                <LocationOnIcon fontSize='inherit' />
              </IconButton>
            </Typography>
          </Box>
          {props.children}
        </Box>
        <DropoffDetailDialog open={open} setOpen={setOpen} />
      </Container>
    </StyledEngineProvider>
  );
};

export default SponsorDropoffHeader;
