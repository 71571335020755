import React from 'react';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import styles from '../SponsorForm.module.css';

const SelectedChildCard = ({ child, onToggle }) => {
  return (
    <Card
      onClick={() => onToggle(child)}
      sx={{
        background: 'lightgrey',
        height: 'auto',
        borderRadius: '10px !important',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
        <img
          src='/assets/images/angel_halo.png'
          alt='hero girl'
          className={styles.angelHalo}
        />
      </Box>
      <CardContent>
        <Box className={styles.angelCardContent}>
          <Typography variant='h5' component='h5' gutterBottom>
            Hi, My name is{' '}
            <span className={styles.childFont}>{child.childName}</span>.
          </Typography>
          <Typography variant='h5' component='h5' gutterBottom>
            I am <span className={styles.childFont}>{child.age}</span> years
            old.
          </Typography>
          <Typography variant='h5' component='h5' gutterBottom>
            With your help, my wish can come true.
          </Typography>

          <Divider className={styles.angelCardDivider} />

          <Typography variant='h5' component='h5' gutterBottom>
            This season, I wish to get:
          </Typography>
          <Typography variant='h5' component='h5' gutterBottom>
            <span className={styles.childFont}>{child.wish}</span>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SelectedChildCard;
