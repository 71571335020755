import React, { useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import PickChildren from './Step1/PickChildren';
import SponsorInfo from './Step2/SponsorInfo';
import Preview from './Step3/Preview';
import { Container } from '@mui/material';
import SponsorFormClosed from './SponsorFormClosed';
import { useSelector } from 'react-redux';
import { selectConfigByKey } from '../../../custom-slices/configurationSlice';

const API_URL = process.env.REACT_APP_API_URL;
const testingURLArray = ['http://localhost:5500', 'https://angeltree-server-test.ufvsus.ca'];

const SponsorForm = () => {
  const configsState = useSelector(state => state.configuration.configs);

  const angelTreeTimeline = selectConfigByKey(configsState, 'angel-tree-timeline');

  const openOnProduction = new Date().getTime() >= new Date(angelTreeTimeline?.sponsorship?.startDate).getTime() && new Date().getTime() <= new Date(angelTreeTimeline?.sponsorship?.endDateCheck).getTime() ? 0 : -1;
  const [step, setStep] = useState(testingURLArray.includes(API_URL) ? 0 : openOnProduction);

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  switch (step) {
    case 0:
      return (
        <StyledEngineProvider injectFirst>
          <PickChildren step={step} nextStep={nextStep} />
        </StyledEngineProvider>
      );

    case 1:
      return (
        <StyledEngineProvider injectFirst>
          <Container>
            <SponsorInfo step={step} prevStep={prevStep} nextStep={nextStep} />
          </Container>
        </StyledEngineProvider>
      );

    case 2:
      return (
        <StyledEngineProvider injectFirst>
          <Container>
            <Preview
              step={step}
              prevStep={prevStep}
              nextStep={nextStep}
              setStep={setStep}
            />
          </Container>
        </StyledEngineProvider>
      );
    default:
      return (
        <StyledEngineProvider injectFirst>
          <SponsorFormClosed angelTreeTimeline={angelTreeTimeline} />
        </StyledEngineProvider>
      );
  }
};

export default SponsorForm;
