import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import GlassCard from '../../../customs/GlassCard';
import CustomStepper from '../CustomStepper';
import SponsorFormContainer from '../SponsorFormContainer';
import FormContainer from '../../../customs/FormContainer';
import DropoffDetailDialog from '../../DropoffDetailDialog';
import { CustomTextField } from '../../../customs/CustomTextField';
import { CustomSelectField } from '../../../customs/CustomSelectField';

// Import React-Hook-Forms and yup
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
import { setSponsorFormValues } from '../../sponsorFormSlice';

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number')
    .required('Full Name Required'),
  email: yup
    .string()
    .email('Incorrect Email Format')
    .required('Email Required'),
  phone: yup
    .string()
    .required('Phone number required')
    .min(14, 'Phone has 10 digits')
    .max(14, 'Phone has 10 digits'),
  title: yup.string().required('Title required'),
  dropoffLocation: yup.string().required('Drop-off Location required'),
});

const SponsorInfo = ({ step, prevStep, nextStep }) => {
  const [open, setOpen] = useState(false);

  const formValues = useSelector((state) => state.sponsor.sponsorForm.values);
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      title: formValues.title,
      dropoffLocation: formValues.dropoffLocation,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const goBack = () => {
    prevStep();
  };

  const goNext = (data) => {
    dispatch(setSponsorFormValues(data));
    nextStep();
  };

  const normalizePhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value, 'CA');

    if (!phoneNumber) return value;

    return phoneNumber.formatNational();
  };

  return (
    <SponsorFormContainer>
      <CustomStepper step={step} />

      <GlassCard sx={{ marginTop: 5, width: '100%' }}>
        <Box>
          <Typography variant='h5' component='h5'>
            Sponsor's information
          </Typography>
        </Box>

        <FormContainer>
          <form onSubmit={handleSubmit(goNext)}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='flex-start'
              marginTop='2rem'
              marginBottom='2rem'
            >
              <CustomTextField
                label='Full Name'
                type='text'
                variant='outlined'
                placeholder='John Doe'
                focusColor='#FDB91B'
                fullWidth
                {...register('name')}
                helperText={errors?.name?.message}
              />

              <CustomTextField
                label='Email'
                type='email'
                variant='outlined'
                placeholder='john.doe@ufv.ca'
                focusColor='#FDB91B'
                fullWidth
                {...register('email')}
                helperText={errors?.email?.message}
              />
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='flex-start'
              marginTop='2rem'
              marginBottom='2rem'
            >
              {/* Using Controller to change the format of the phone when user inputs general phone number */}
              <Controller
                name='phone'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    label='Phone Number'
                    type='text'
                    variant='outlined'
                    placeholder='604-123-4567'
                    focusColor='#FDB91B'
                    fullWidth
                    helperText={errors?.phone?.message}
                    onChange={(event) =>
                      field.onChange(normalizePhoneNumber(event.target.value))
                    }
                  />
                )}
              />

              <FormControl fullWidth>
                <InputLabel
                  id='title-label'
                  sx={{
                    '&.Mui-focused': {
                      color: '#FDB91B',
                    },
                    color: 'white',
                  }}
                >
                  What describes you the best?
                </InputLabel>

                <Controller
                  name='title'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <CustomSelectField
                      {...field}
                      label='What describes you the best?'
                      labelId='title-label'
                      id='title'
                      sx={{ width: '100%' }}
                    >
                      <MenuItem value='' disabled>
                        <em style={{ color: '#CE4D4D' }}>Please select one</em>
                      </MenuItem>
                      <MenuItem value={'Student'}>Student</MenuItem>
                      <MenuItem value={'Administration/Staff'}>
                        Administration/Staff
                      </MenuItem>
                      <MenuItem value={'Faculty'}>Faculty</MenuItem>
                      <MenuItem value={'Alumni'}>Alumni</MenuItem>
                      <MenuItem value={'External'}>External</MenuItem>
                    </CustomSelectField>
                  )}
                />

                {errors?.title?.message && (
                  <FormHelperText sx={{ color: 'yellow' }}>
                    {errors?.title?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <FormControl fullWidth>
              <InputLabel
                id='dropoff-location-label'
                sx={{
                  '&.Mui-focused': {
                    color: '#FDB91B',
                  },
                  color: 'white',
                }}
              >
                Drop-off Location
              </InputLabel>

              <Controller
                name='dropoffLocation'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <CustomSelectField
                    {...field}
                    label='Drop-off Location'
                    labelId='dropoff-location-label'
                    id='dropoff-location'
                    sx={{ width: '100%' }}
                  >
                    <MenuItem value='' disabled>
                      <em style={{ color: '#CE4D4D' }}>
                        Please select a drop-off location
                      </em>
                    </MenuItem>
                    <MenuItem value={'Abbotsford'}>
                      Abbotsford: Abby Campus, S Building
                    </MenuItem>
                    <MenuItem value={'Chilliwack'}>
                      Chilliwack: CEP Campus, A Building
                    </MenuItem>
                  </CustomSelectField>
                )}
              />

              {errors?.dropoffLocation?.message && (
                <FormHelperText sx={{ color: 'yellow' }}>
                  {errors?.dropoffLocation?.message}
                </FormHelperText>
              )}
              <Typography variant='p' component='p' sx={{ marginTop: '2rem' }}>
                For drop-off detail, please click{' '}
                <Link
                  href='#'
                  color='secondary'
                  onClick={() => setOpen((prev) => !prev)}
                >
                  here
                </Link>
                .
              </Typography>
            </FormControl>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='space-between'
              marginTop='2rem'
            >
              <Button
                color='secondary'
                size='large'
                variant='outlined'
                onClick={goBack}
              >
                Previous
              </Button>
              <Button
                type='submit'
                color='secondary'
                size='large'
                variant='contained'
              >
                Next
              </Button>
            </Stack>
          </form>
        </FormContainer>
      </GlassCard>
      <DropoffDetailDialog open={open} setOpen={setOpen} />
    </SponsorFormContainer>
  );
};

export default SponsorInfo;
