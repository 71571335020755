import { useState } from 'react';
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Link
} from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HeaderCard from '../layout/customs/header/HeaderCard';
import GlassCard from '../customs/GlassCard';
import PickupDetailDialog from '../parent/PickupDetailDialog';


const ParentFAQ = () => {
  const [openPickupDialog, setOpenPickupDialog] = useState(false);

  const Applicants = () => {
    return <List>
      <ListItem>
        <ListItemIcon>
          <CheckBoxIcon color='secondary' />
        </ListItemIcon>
        <ListItemText>You are a current UFV student</ListItemText>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <CheckBoxIcon color='secondary' />
        </ListItemIcon>
        <ListItemText>
          You are the legal guardian for children 16 or younger
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <CheckBoxIcon color='secondary' />
        </ListItemIcon>
        <ListItemText>You are able to pick up or arrange gift pick-up at either of our preferred locations.
        </ListItemText>
      </ListItem>
    </List>
  }

  const GiftGuideline = () => {
    return <List>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon fontSize='small' color='secondary' />
        </ListItemIcon>
        <ListItemText>To ensure a fair and reasonable process, please keep in mind that wish requests
          should fall within the price range of $50 to $75 per Angel.
          This can include a single wish or multiple wishes combined. </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon fontSize='small' color='secondary' />
        </ListItemIcon>
        <ListItemText>
          Please add details to the desired wish(es)—for example, colour, size, etc.
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon fontSize='small' color='secondary' />
        </ListItemIcon>
        <ListItemText>Taking the time and providing as much detail as possible will ensure a timely fulfilment of the wish(es).
        </ListItemText>
      </ListItem>
    </List>
  }

  const ProgramStages = () => {
    return <List>
      <ListItem>
        <ListItemText><span style={{ fontWeight: 'bold' }}>1. Registration for Angels:</span> Applications are open to
          eligible UFV students who are the guardians of children aged 16 or younger.
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemText><span style={{ fontWeight: 'bold' }}>2. Fulfilling Wishes:</span> The program opens to our UFV and external communities to
          sponsor and fulfill our Angel's holiday wishes.
        </ListItemText>
      </ListItem>

      <ListItem>
        <ListItemText><span style={{ fontWeight: 'bold' }}>3. Drop-Off & Angel Pick-Up:</span> Angels collect gifts/wishes at designated locations.
          (Click <Button onClick={() => setOpenPickupDialog(prev => !prev)} color='secondary' size="small" variant="outlined">here</Button> for pick-up location & dates)
        </ListItemText>
      </ListItem>
    </List>
  }

  return (
    <Container>
      <HeaderCard
        title='ANGEL FREQUENTLY ASKED QUESTIONS'
        icon={<QuizIcon sx={{ fontSize: 60 }} />}
      />

      <GlassCard>
        <Box>
          <Typography
            gutterBottom
            variant='h5'
            fontWeight={'bold'}
            component='h5'
            color='secondary'
          >
            What is the Angel Tree Program?
          </Typography>
          <Typography varient='p' component='p'>
            Every year during the holiday season, the UFV Student Union Society operates the Angel Tree Program.
            This meaningful program helps UFV students balancing education and family to collect holiday wishes for their Angels with community support.
          </Typography>
          <br />
          <Typography varient='p' component='p'>
            The program has three stages:
          </Typography>

          <ProgramStages />

          <Typography
            gutterBottom
            variant='h5'
            fontWeight={'bold'}
            component='h5'
            color='secondary'
          >
            Who are Angels?
          </Typography>
          <Typography varient='p' component='p'>
            Angels are the children of UFV students who are accepted to be a part of the Angel Tree Program.
            Applicants must meet the following requirements to be accepted for the program:
          </Typography>

          <Applicants />

          <Typography variant='p' component='p' fontStyle='italic'>
            It is important to note that proof of guardianship might be requested at any time*
          </Typography>

          <br />

          <Typography
            gutterBottom
            variant='h5'
            fontWeight={'bold'}
            component='h5'
            color='secondary'
          >
            What are Angel's wishes?
          </Typography>
          <Typography varient='p' component='p'>
            Wishes are gifts that Angels are asking for this holiday season; Angel guardians can submit their wishes through the <Link sx={{ textDecoration: 'none', fontWeight: 'bold', color: 'white' }} href='/parent/form'>Angel Application</Link>.
            Once Angels are accepted into the program, SUS will display these wishes on the Angel Tree Website, enabling sponsors to search for Angel's wishes to fulfill this holiday season.
            Please refer to our wish guideline below for some helpful recommendations.
          </Typography>

          <br />

          <Typography varient='p' component='p'>
            SUS will not share personal information with external members other than Angel's first name, age and wishes.
            View the Angel Tree program's Terms and Conditions and Privacy Policy <Link sx={{ textDecoration: 'none', fontWeight: 'bold', color: 'white' }} href='/legal/terms'>here</Link>.
          </Typography>

          <br />

          <Typography
            gutterBottom
            variant='h5'
            fontWeight={'bold'}
            component='h5'
            color='secondary'
          >
            Any wish guidelines?
          </Typography>
          <Typography varient='p' component='p'>
            We ask you to be reasonable and mindful when requesting wishes for Angels. We do recommend the following;
          </Typography>

          <GiftGuideline />

          <Typography
            gutterBottom
            variant='h5'
            fontWeight={'bold'}
            component='h5'
            color='secondary'
          >
            How do I know if I qualify to apply for the program?
          </Typography>

          <Typography varient='p' component='p'>
            You qualify for the Angel Tree Program if you meet the following
            statements.
          </Typography>

          <Applicants />

          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Note:</span> Even if you don't meet the full criteria, we encourage you to apply for the program.
            Our team will review your application and will be in contact to confirm your eligibility.
          </Typography>

          <br />

          <Typography
            gutterBottom
            variant='h5'
            fontWeight={'bold'}
            component='h5'
            color='secondary'
          >
            What's the timeline for Angel Tree?
          </Typography>
          <Typography varient='p' component='p'>
            The Angel Tree program runs from early November until the holiday break in December.
            You can access the program timeline for application due dates, sign-ups, pick-up locations, and times on the <Link sx={{ textDecoration: 'none', fontWeight: 'bold', color: 'white' }} href='/'>home page</Link>.
          </Typography>
        </Box>
      </GlassCard>
      <PickupDetailDialog open={openPickupDialog} setOpen={setOpenPickupDialog} />
    </Container>
  );
};

export default ParentFAQ;
