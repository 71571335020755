import React, { useState } from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  ListItemIcon,
  Tab,
  Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import GlassCard from '../customs/GlassCard';
import HeaderCard from '../layout/customs/header/HeaderCard';
import GavelIcon from '@mui/icons-material/Gavel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { angelTerms, sponsorTerms } from './termContents';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#FDB91B',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '20px',
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#FDB91B',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  })
);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Terms = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      <HeaderCard
        title='TERMS AND CONDITIONS'
        icon={<GavelIcon sx={{ fontSize: 60 }} />}
      />

      <Box
        sx={{
          borderBottom: 'none',
          marginBottom: '2rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <StyledTab label='Angel' {...a11yProps(0)} />
          <StyledTab label='Sponsor' {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GlassCard>
          <Typography variant='h6' component='h6' gutterBottom>
            Angel Terms and Conditions
          </Typography>
          <Box>
            <List>
              {angelTerms.map((term) => (
                <ListItem key={term.id} disablePadding sx={{ marginBottom: 2 }}>
                  <ListItemIcon sx={{ color: 'white' }}>
                    <CheckBoxIcon />
                  </ListItemIcon>
                  <ListItemText>{term.content}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </GlassCard>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GlassCard>
          <Typography variant='h6' component='h6' gutterBottom>
            Sponsor Terms and Conditions{' '}
          </Typography>
          <Box>
            <List>
              {sponsorTerms.map((term) => (
                <ListItem key={term.id} disablePadding sx={{ marginBottom: 2 }}>
                  <ListItemIcon sx={{ color: 'white' }}>
                    <CheckBoxIcon />
                  </ListItemIcon>
                  <ListItemText>{term.content}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </GlassCard>
      </TabPanel>
    </Container>
  );
};

export default Terms;
