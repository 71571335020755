import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import GlassCard from '../../../customs/GlassCard';
import styles from './HeaderCard.module.css';
import { Typography } from '@mui/material';

const HeaderCard = (props) => {
  return (
    <StyledEngineProvider>
      <GlassCard className={styles.bg} sx={{ color: '#fdb91b' }}>
        {props.icon}
        <Typography
          variant='h5'
          component='h5'
          sx={{
            fontFamily: `'Ubuntu', sans-serif`,
            letterSpacing: '4px',
          }}
          fontWeight='bold'
        >
          {props.title}
        </Typography>
      </GlassCard>
    </StyledEngineProvider>
  );
};

export default HeaderCard;
