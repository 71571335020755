import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import GlassCard from '../../customs/GlassCard';
import styles from './SponsorForm.module.css';

const ChildCard = ({ child, isSelected, onToggle }) => {
  return (
    <GlassCard
      className={isSelected ? styles.selected : styles.card}
      onClick={onToggle}
    >
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <img
          src='/assets/images/angel_halo.png'
          alt='hero girl'
          className={styles.angelHalo}
        />
      </Box>
      <Box className={styles.angelCardContent}>
        <Typography variant='h5' component='h5' gutterBottom>
          Hi, My name is{' '}
          <span className={styles.childFont}>{child.childName}</span>.
        </Typography>
        <Typography variant='h5' component='h5' gutterBottom>
          I am <span className={styles.childFont}>{child.age}</span> years old.
        </Typography>
        <Typography variant='h5' component='h5' gutterBottom>
          With your help, my wish can come true.
        </Typography>
        <Divider className={styles.angelCardDivider} />
        <Typography variant='h5' component='h5' gutterBottom>
          This season, I wish to get:
        </Typography>
        <Typography variant='h5' component='h5' gutterBottom>
          <span className={styles.childFont}>{child.wish}</span>
        </Typography>
      </Box>
    </GlassCard>
  );
};

export default ChildCard;
