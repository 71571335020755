import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const fetchChildren = createAsyncThunk('sponsorForm/fetchChildren', async (_, { signal, rejectWithValue }) => {
  try {
    const response = await axios.get(`${API_URL}/child?approved=true&isSponsored=false`, { signal });
    const shuffledChildren = shuffle(response.data.data);
    return shuffledChildren;
  } catch (error) {
    if (axios.isCancel(error)) {
      return rejectWithValue('Request cancelled');
    }
    return rejectWithValue(error.response?.data?.message || 'Sorry, something has gone wrong. Please contact an administrator..');
  }
});

export const checkSponsorship = createAsyncThunk('sponsorForm/checkSponsorship', async (selectedChildrenIds, { rejectWithValue }) => {
  try {
    await axios.post(`${API_URL}/child/check`, selectedChildrenIds, config);
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

export const submitSponsorFormToServer = createAsyncThunk(
  'sponsorForm/submitSponsorFormToServer',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/sponsor`, formData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data
      );
    }
  }
);

const initialSponsorFormState = {
  children: [],
  selectedChildren: [],
  values: {},
  dataFromServer: {},
  loading: false,
  success: false,
  error: null,
  checkingLoading: false,
  checkingSuccess: false,
  checkingError: null,
  submitLoading: false,
  submitSuccess: false,
  submitError: null,
};

const sponsorFormSlice = createSlice({
  name: 'sponsorForm',
  initialState: initialSponsorFormState,
  reducers: {
    filterSelectedChildren: (state) => {
      // Automatically generated memoized selector
      state.selectedChildren = state.children.filter((child) => Array.from(state.values?.selectedChildrenIds).includes(child.childId));
    },
    setSponsorFormValues: (state, action) => {
      state.values = { ...state.values, ...action.payload };
    },
    resetSponsorFormError: (state) => {
      state.error = null;
      state.checkingError = null;
    },
    resetSponsorFormValues: (state) => {
      Object.assign(state, initialSponsorFormState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChildren.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChildren.fulfilled, (state, action) => {
        state.children = action.payload;
        state.loading = false;
        state.success = true;
      })
      .addCase(fetchChildren.rejected, (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(checkSponsorship.pending, (state) => {
        state.checkingLoading = true;
      })
      .addCase(checkSponsorship.fulfilled, (state) => {
        state.checkingSuccess = true;
        state.checkingLoading = false;
        state.checkingError = null;
      })
      .addCase(checkSponsorship.rejected, (state, action) => {
        state.checkingSuccess = false;
        state.checkingLoading = false;
        state.checkingError = action.payload;
      })
      .addCase(submitSponsorFormToServer.pending, (state) => {
        state.submitLoading = true;
      })
      .addCase(submitSponsorFormToServer.fulfilled, (state, action) => {
        state.submitLoading = false;
        state.submitSuccess = true;
        state.submitError = null;
        state.values = {};
        state.dataFromServer = action.payload;
      })
      .addCase(submitSponsorFormToServer.rejected, (state, action) => {
        state.submitLoading = false;
        state.submitSuccess = false;
        state.submitError = action.payload;
      });
  },
});

export const {
  filterSelectedChildren,
  setSponsorFormValues,
  resetSponsorFormError,
  resetSponsorFormValues,
} = sponsorFormSlice.actions;

export default sponsorFormSlice.reducer;

/**
 * Reference: https://bost.ocks.org/mike/shuffle/
 * Shuffle the array
 * @param {*} array
 * @returns the shuffled array
 */
function shuffle(array) {
  var m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}
