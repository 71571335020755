import React from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomCard = styled(Paper)(({ theme }) => ({
  borderRadius: '10px',
  padding: '2rem',
  color: 'white',
  backgroundColor: 'rgba(0,0,0, 0.2)',
  backdropFilter: 'blur(01rem)',
  WebkitBackdropFilter: 'blur(1rem)',
  boxShadow: '10px 10px 10px rgba(30, 30, 30, 0.1)',
  zIndex: 5,
}));

const GlassCard = React.forwardRef((props, ref) => (
  <CustomCard {...props} ref={ref}>
    {props.children}
  </CustomCard>
));

export default GlassCard;
