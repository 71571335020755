import React, { useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { Container, Box, Typography, Link, IconButton } from '@mui/material';
import styles from './ParentPickup.module.css';
import HeaderCard from '../../layout/customs/header/HeaderCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpIcon from '@mui/icons-material/Help';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PickupDetailDialog from '../PickupDetailDialog';

const ParentPickupHeader = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledEngineProvider injectFirst>
      <Container>
        <HeaderCard
          title='PICK UP INFOMARTION'
          icon={<CardGiftcardIcon sx={{ fontSize: 60 }} />}
        />
        <Box className={styles.mainBox}>
          <Box>
            <Typography variant='body1' component='p'>
              SUS Team will be offering multiple days for pick-up to create a
              seamless, hassle-free opportunity for all angel families.
            </Typography>
          </Box>

          <Typography variant='body1' component='p'>
            <Link
              href='/parent/faq'
              target='_blank'
              variant='body1'
              color='inherit'
            >
              Frequently Asked Questions
            </Link>
            <IconButton
              color='secondary'
              aria-label='help'
              sx={{ fontSize: '30px' }}
              href='/parent/faq'
              target='_blank'
            >
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Typography>

          <Typography variant='body1' component='p'>
            <Link
              href='#'
              variant='body1'
              color='inherit'
              onClick={() => setOpen((prev) => !prev)}
            >
              Pick-up Detail
            </Link>
            <IconButton
              color='secondary'
              aria-label='help'
              sx={{ fontSize: '30px' }}
              onClick={() => setOpen((prev) => !prev)}
            >
              <LocationOnIcon fontSize='inherit' />
            </IconButton>
          </Typography>
          {props.children}
        </Box>
        <PickupDetailDialog open={open} setOpen={setOpen} />
      </Container>
    </StyledEngineProvider>
  );
};

export default ParentPickupHeader;
