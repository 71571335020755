import React from 'react';
import { Container, Box, Grid, Button, Typography } from '@mui/material';
import styles from './HomePage.module.css';

const Section1 = () => {
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={3}
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Box id="homepage-heading-image" sx={{ textAlign: 'center' }}>
              <img
                src='/assets/images/angel_hero.png'
                alt='Angel Hero'
                className={styles.image}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box>
              <Box sx={{ color: 'white', marign: 2 }}>
                <Typography
                  variant='h4'
                  gutterBottom
                  component='h4'
                  className={styles.headline}
                >
                  Welcome
                </Typography>
                <Typography
                  variant='h3'
                  gutterBottom
                  component='div'
                  sx={{ fontWeight: 'bold' }}
                  className={styles.headline}
                >
                  <span style={{ fontWeight: 'lighter' }}>to</span> Angel Tree
                </Typography>
                <Typography
                  variant='p'
                  gutterBottom
                  component='p'
                  sx={{ fontSize: 20, marginTop: '2rem' }}
                >
                  Each year the Angel Tree Program creates opportunities for
                  local community members to support our UFV students during the
                  holiday season.
                </Typography>

                <Typography
                  variant='p'
                  gutterBottom
                  component='p'
                  sx={{ fontSize: 20, marginTop: 6 }}
                >
                  The Student Union Society operates the Angel Tree Program
                  annually around the holiday months. The program strives to
                  help support UFV students raising families while pursuing
                  their education. It's one of the ways we are giving back to
                  the community around us.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'block',
                  float: 'left',
                  textAlign: 'center',
                  marginTop: 4,
                }}
              >
                <Button
                  variant='contained'
                  href='/parent/form'
                  color='secondary'
                  size='large'
                  sx={{ margin: 2, marginLeft: 0, color: '#2C2C2C' }}
                >
                  Register an Angel
                </Button>
                <Button
                  variant='contained'
                  href='/sponsor/form'
                  size='large'
                  sx={{ margin: 2, marginLeft: 0 }}
                >
                  Sponsor an Angel
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Section1;
