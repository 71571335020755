import React, { useState } from 'react';
import styles from './Navbar.module.css';
import {
  AppBar,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Link,
  IconButton,
} from '@mui/material';

// Import icons
import MenuIcon from '@mui/icons-material/Menu';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

import { StyledEngineProvider } from '@mui/material/styles';

const Navbar = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Box className={styles.alignCenter}>
          <IconButton color='inherit'>
            <EscalatorWarningIcon />
          </IconButton>
          <Link color='inherit' href='/parent/form' underline='none'>
            Register An Angel
          </Link>
        </Box>
      </MenuItem>
      <MenuItem>
        <Box className={styles.alignCenter}>
          <IconButton color='inherit'>
            <ChildCareIcon />
          </IconButton>
          <Link color='inherit' href='/sponsor/form' underline='none'>
            Sponsor An Angel
          </Link>
        </Box>
      </MenuItem>
      <MenuItem>
        <Box className={styles.alignCenter}>
          <IconButton color='inherit'>
            <DeliveryDiningIcon />
          </IconButton>
          <Link color='inherit' href='/sponsor/dropoff' underline='none'>
            Search Drop-off
          </Link>
        </Box>
      </MenuItem>
      <MenuItem>
        <Box className={styles.alignCenter}>
          <IconButton color='inherit'>
            <CardGiftcardIcon />
          </IconButton>
          <Link color='inherit' href='/parent/pickup' underline='none'>
            Search Pick-up
          </Link>
        </Box>
      </MenuItem>
      <MenuItem>
        <Box className={styles.alignCenter}>
          <IconButton color='inherit'>
            <ContactMailIcon />
          </IconButton>
          <Link
            color='inherit'
            href='https://ufvsus.ca/contact'
            underline='none'
          >
            Contact Us{' '}
          </Link>
        </Box>
      </MenuItem>
    </Menu>
  );

  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static'>
          <Toolbar sx={{ padding: 0 }}>
            <Box>
              <Link href='/'>
                <img
                  src='/assets/images/angel_tree_logo.png'
                  alt='Angel Tree Logo'
                  className={styles.logo}
                />
              </Link>
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Box
              sx={{
                display: { xs: 'none', md: 'flex', gap: '2rem' },
              }}
            >
              <Box>
                <Link color='inherit' href='/parent/form' underline='none'>
                  Register An Angel
                </Link>
              </Box>
              <Box>
                <Link color='inherit' href='/sponsor/form' underline='none'>
                  Sponsor An Angel
                </Link>
              </Box>
              <Box>
                <Link color='inherit' href='/sponsor/dropoff' underline='none'>
                  Search Drop-off
                </Link>
              </Box>
              <Box>
                <Link color='inherit' href='/parent/pickup' underline='none'>
                  Search Pick-up
                </Link>
              </Box>
              <Box>
                <Link
                  color='inherit'
                  href='https://ufvsus.ca/contact'
                  underline='none'
                  target='_blank'
                >
                  Contact Us
                </Link>
              </Box>
            </Box>

            {/* Show the More Icon when the window's screen resized */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        <Toolbar id='back-to-top-anchor' />
      </Box>
    </StyledEngineProvider>
  );
};

export default Navbar;
